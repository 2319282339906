import {message, notification} from "antd";

export function notificationFailure() {
    message.error({
        duration: 2,
        content: 'Something is wrong',
    });
}

export function notificationNameUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Name Updated',
    });
}

export function notificationFactionUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Faction Updated',
    });
}
export function notificationUnitNameUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Unit Name Updated',
    });
}
export function notificationUnitUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Unit Updated',
    });
}

export function notificationKeywordsUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Keywords Updated',
    });
}

export function notificationEquipmentsUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Equipments Updated',
    });
}

export function notificationEnhancementsUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Enhancements Updated',
    });
}

export function notificationPointsUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Points Updated',
    });
}

export function notificationCrusadePointsUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Crusade Points Updated',
    });
}

export function notificationExperiencePointsUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Experience Points Updated',
    });
}

export function notificationRankUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Rank Updated',
    });
}

export function notificationBattlesPlayedUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Battles Played Updated',
    });
}

export function notificationBattlesSurvivedUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Battles Survived Updated',
    });
}

export function notificationEnemyUnitsDestroyedUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Enemy Units Destroyed Updated',
    });
}

export function notificationBattleHonorsUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Battle Honors Updated',
    });
}

export function notificationBattleScarsUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Battle Scars Updated',
    });
}

