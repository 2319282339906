import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {me} from "./UserConnector";
import {getToken} from "./auth/Auth";
import {useNavigate, useSearchParams} from "react-router-dom";
import {doRestCall, isHome} from "../AppUtils";


const CurrentUserContext = createContext({
    currentUser: null,
    setCurrentUser: () => { },
    setNeedRefresh: () => { },
    ref: null,
    setRef: () => { }
});

export const useCurrentUserContext = () => useContext(CurrentUserContext)

export const CurrentUserContextProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState();
    const [needRefresh, setNeedRefresh] = useState(true);
    const [ref, setRef] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        setNeedRefresh(!isHome())
    }, [])

    function onSuccess(response) {
        setCurrentUser(response.body)
      //  setNeedRefresh(false)
    }

    function onError() {
        setNeedRefresh(false)
        setCurrentUser(undefined)
    }

    useEffect(() => {
        !isHome() && doRestCall(navigate, '/user/me', 'get', null, null, onSuccess, onError)
    }, [needRefresh]);

    const result = useMemo(() => {
        return {
            currentUser,
            setCurrentUser,
            setNeedRefresh,
            ref,
            setRef
        }
    }, [currentUser, setCurrentUser, setNeedRefresh, ref, setRef])

    return <CurrentUserContext.Provider value={result}>{children}</CurrentUserContext.Provider>
}

