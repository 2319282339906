import React, {useEffect, useState} from 'react';
import {Alert, Button, Divider, Form, Select, Space, Spin, Steps} from 'antd';
import moment from "moment";
import {isRegistered, register, unregister} from "../../TournamentConnector";
import Factions from "./Factions";

const Registration = ({ status, tournamentId, onTournamentUpdate }) => {

    const [registrationStatus, setRegistrationStatus] = useState(true);
    const [selectedFaction, setSelectedFaction] = useState({});

    useEffect(() => {
        if (tournamentId !== undefined && tournamentId !== null) {
            isRegistered(tournamentId)
                .then(response => {
                    setRegistrationStatus(response.body)
                })
        }
    }, [tournamentId, registrationStatus]);

    const registerMe = () => {
        setRegistrationStatus(undefined)

        let updatedTournament = register(tournamentId, selectedFaction);
        updatedTournament.then(response => {
            onTournamentUpdate()
        })
    };

    const unRegisterMe = () => {
        setRegistrationStatus(undefined)

        let updatedTournament = unregister(tournamentId)

        updatedTournament.then(response => {
            onTournamentUpdate()
        })
    };

    return <>
        {status && status === 'CREATED' && <Space>
            <Alert className="tournRegistrationAlert" message="Registration not started yet" type={"warning"} showIcon />
        </Space>}
        {status && status === 'REGISTRATION_OPENED' && <>

            {registrationStatus === 'NOT_REGISTERED' && <Space direction={"vertical"}>
                <Alert className="tournRegistrationAlert" message="Registration is open" type={"success"} showIcon />
                <Factions setSelectedFaction={setSelectedFaction}  />
                <Button onClick={registerMe}>register for tournament</Button>
            </Space>}

            {registrationStatus === 'REGISTERED' && <Space direction={"vertical"}>
                <Alert message="You are already registered" type={"info"} showIcon />
                <Button onClick={unRegisterMe}>unregister for tournament</Button>
            </Space>}
        </>}
        {status && status === 'REGISTRATION_CLOSED' && <Space >
            <Alert message="Registration is closed" type={"info"} showIcon />
        </Space> }
    </>
}

export default Registration;