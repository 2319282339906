// https://habr.com/ru/articles/732864/

import React from 'react';
import {useNavigate} from "react-router-dom";
import {validateToken} from "./AuthConnector";
import {useCurrentUserContext} from "../CurrentUserContext";

const TOKEN_KEY = 'token';
const TOKEN_TTL_MS = 86340000;  // 24 hours

const Auth = () => {
    const navigate = useNavigate();
    const { currentUser } = useCurrentUserContext();

    validateToken()
        .catch(error => {
            console.log(error);
            if (error === undefined || error === null) {
                navigate('/user/login')
            }
            if (error.response === undefined || error.response === null) {
                navigate('/user/login')
            }
            if (error.response.status === undefined || error.response.status === null || error.response.status === 403) {
                navigate('/user/login')
            }
        })

    return (
        (!isUserAuthenticated(currentUser) && !isAdminAuthenticated(currentUser)) && navigate('/user/login')
    );
};

const isUserAuthenticated = (currentUser) => {
    let result = getToken() && !isExpired(getToken().timeStamp) && currentUser && "USER" === currentUser.role
    return result;
}

export const isAdminAuthenticated = (currentUser) => {
    let result = getToken() && !isExpired(getToken().timeStamp) && currentUser && "ADMIN" === currentUser.role
    return result;
}

export const isAnonymous = (currentUser) => {
    let result = currentUser === null || currentUser === undefined || getToken().value === null || getToken().value === undefined;
    return result
}

const getToken = () => {
    let result = null;

    const storedToken = sessionStorage.getItem(TOKEN_KEY);
    storedToken && (result = JSON.parse(storedToken));

    if (result === null) {
        result = JSON.stringify({
            value: "no_token",
            timeStamp: 0,
        })
    }

    return result;
};

const removeToken = () => {
    sessionStorage.removeItem(TOKEN_KEY);
};

const saveToken = (access_token) => {
    sessionStorage.setItem(
        TOKEN_KEY,
        JSON.stringify({
            value: access_token,
            timeStamp: new Date().getTime(),
        })
    );
};

const isExpired = (timeStamp) => {

    if (!timeStamp) return true;

    const now = new Date().getTime();
    const diff = now - timeStamp;

    return diff > TOKEN_TTL_MS;
};


export {Auth, saveToken, isUserAuthenticated, removeToken, getToken}

// https://habr.com/en/articles/732432/
// https://habr.com/ru/companies/timeweb/articles/598727/
// https://habr.com/ru/articles/732864/