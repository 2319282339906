import axios from "axios";
import {getToken} from "../user/auth/Auth";
import {restRoute} from "../AppUtils";

export function changeHistory(type, id) {
    return axios
        .get(restRoute() + '/changehistory', {
            params: {
                targetType: type,
                targetId: id
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function loadGameSettings() {
    return axios
        .get(restRoute() + '/game/settings', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function createGame(game) {
    return axios
        .post(restRoute() + '/game/create', game, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
export function updateGame(game) {
    return axios
        .put(restRoute() + '/game/update', game, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function validateRoster(rosterId, gameSettings) {
    return axios
        .post(restRoute() + '/roster/validate', {
            gameSettings: gameSettings,
            rosterId: rosterId
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });

}

export function gameView(gameId) {
    return axios
        .get(restRoute() + '/game/view/' + gameId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function myGames() {
    return axios
        .get(restRoute() + '/games/my', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

/*
export function loadGames(created, master, participated, all) {
    return axios
        .get(restRoute() + '/games', {
            params: {
                created: created,
                master: master,
                participated: participated,
                all: all
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
*/


export function loadRosters(userId) {
    return axios
        .get(restRoute() + '/game/userrosters',  {
            params: { userId: userId },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function loadCrusadeRosters(userId) {
    return axios
        .get(restRoute() + '/game/usercrusaderosters',  {
            params: { userId: userId },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function codexFactions(universe) {
    return axios
        .get(restRoute() + '/codex/factions', {
            params: {universe: universe},
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}


export function codexUniverses() {
    return axios
        .get(restRoute() + '/codex/universes', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}


