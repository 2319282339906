import React from "react";
import {Route, Routes} from "react-router-dom";
import TournamentCreate from "./create/TournamentCreate";
import TournamentView from "./view/TournamentView";
import TournamentEdit from "./edit/TournamentEdit";
import Tournaments from "./all/Tournaments";
import TournamentBoard from "./board/TournamentBoard";

export function TournamentRouter() {
    return (
        <Routes>
            <Route path="/tournament/create" element={<TournamentCreate/>}/>
            <Route path="/tournaments" element={<Tournaments/>}/>
            <Route path="/tournament/view/:tournamentId" element={<TournamentView/>}/>
            <Route path="/tournament/board/:tournamentId" element={<TournamentBoard/>}/>
            <Route path="/tournament/edit/:tournamentId" element={<TournamentEdit/>}/>
        </Routes>
    );
}
