import React from "react";
import {Route, Routes} from "react-router-dom";
import CrusadeRosterCreate from "./create/CrusadeRosterCreate";
import CrusadeRosterView from "./view/CrusadeRosterView";
import CrusadeRosterEdit from "./edit/CrusadeRosterEdit";
import CrusadeRostersAll from "./all/CrusadeRostersAll";
import CrusadeRostersMy from "./my/CrusadeRostersMy";

export function CrusadeRosterRouter() {
    return (
        <Routes>
            <Route path="/crusade/roster/create" element={ <CrusadeRosterCreate /> }  />
            <Route path="/crusade/roster/view/:crusadeRosterId" element={ <CrusadeRosterView /> }  />
            <Route path="/crusade/roster/edit/:crusadeRosterId" element={<CrusadeRosterEdit /> } />
            <Route path="/crusade/rosters/all" element={<CrusadeRostersAll /> } />
            <Route path="/crusade/rosters/my" element={<CrusadeRostersMy />} />
        </Routes>
    );
}


