import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./Home";


export function HomeRouter() {
    return (
        <Routes>
            <Route path="/home" element={<Home />}  />
            <Route path="/" element={ <Home /> }  />
        </Routes>
    );
}


