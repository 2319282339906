import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input, InputNumber,
    Select,
    Table
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {createGameResult, forceView} from "./GameResultsConnector";
import {Auth} from "../../user/auth/Auth";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";

const GameResultsCreate = () => {

    GoogleAnalyticsPageView("/crusade/gameresult/create", "Crusade Game Result Create")

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [cards, setCards] = useState([]);

    let {forceId} = useParams();

    useEffect(() => {
        form.setFieldsValue({forceId: forceId});
    }, []);

    const [loading, setLoading] = useState(true)
    const [force, setForce] = useState({});

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        forceView(forceId)
            .then(response => {

                setForce(response.body)
                setLoading(false)

                form.setFieldsValue({forceId: forceId});
                form.setFieldsValue({name: force.name});
            })
            .catch(error => {
                console.log(error)
            })
    }

    const save = (gameResult) => {
        createGameResult(gameResult)
            .then(response => {
                    if (response.valid) {
                        navigate('/crusade/force/' + forceId + '/manage')
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    function onFinish(values) {
        save(values)
    }

    function navigateBack() {
        navigate("/crusade/force/" + forceId + "/manage")
    }

    const onSurvivedChange = (id, value) => {
        let find = cards.find(card => card.id === id);
        if (find === undefined) {
            find = force.cards.find(card => card.id === id);
            find.survived = value;
        } else {
            find.survived = value;
            form.setFieldsValue({ cards: cards });
        }

    }

    const onEnemyDestroyedChange = (id, value) => {
        let find = cards.find(card => card.id === id);
        if (find === undefined) {
            find = force.cards.find(card => card.id === id);
            find.enemyUnitsDestroyed = value;
        } else {
            find.enemyUnitsDestroyed = value;
            form.setFieldsValue({ cards: cards });
        }

    }

    const onMarkOfGreatnessChange = (id, value) => {
        let find = cards.find(card => card.id === id);
        if (find === undefined) {
            find = force.cards.find(card => card.id === id);
            find.markOfGreatness = value;
        } else {
            find.markOfGreatness = value;
            form.setFieldsValue({ cards: cards });
        }

    }

    const cardsColumns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
        render: (name) => (
            <>
                {name}
                <Input name="name" id="name" value={name} type="hidden" />
            </>
        )
    }, {
        title: 'Survived', dataIndex: 'survived', key: 'survived', align: 'center',
        render: (_, row) => (
            <>
                <Checkbox name="survived" onChange={(e) => onSurvivedChange(row.id, e.target.checked)} />
            </>
        )
    }, {
        title: 'Enemy Units Destroyed', dataIndex: 'enemyUnitsDestroyed', key: 'enemyUnitsDestroyed', align: 'center',
        render: (_, row) => (
            <>
                <InputNumber min={0} max={10} defaultValue={0} onChange={(e) => onEnemyDestroyedChange(row.id, e)} />
            </>
        )
    }, {
        title: 'Mark of Greatness', dataIndex: 'markOfGreatness', key: 'markOfGreatness', align: 'center',
        render: (_, row) => (
            <>
                <Checkbox name="markOfGreatness" onChange={(e) => onMarkOfGreatnessChange(row.id, e.target.checked)}/>
            </>
        )
    }];

    const [selectionType, setSelectionType] = useState('checkbox');

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const selectedCards = [];
            selectedRows.map((card) => {
                selectedCards.push({...card});
            });
            setCards(selectedRows);
            form.setFieldsValue({ cards: selectedRows});
        }
    };


    return (
        <>
            <Auth/>

            <p className="forceGameCreateTitle">Game Results Create</p>

            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  style={{width: '100%'}}
                  className="forceGameCreateForm"
                  >

                <Form.Item hidden="true" name="forceId">
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Date"
                    name="date"
                    className="forceGameInputs"
                    >
                    <DatePicker className="forceGameCreateDatePicker"/>
                </Form.Item>

                <Form.Item
                    label="Winner"
                    name="winner"
                    valuePropName="checked"
                    className="forceGameInputs"
                    >
                    <Checkbox />
                </Form.Item>

                <Form.Item name="cards">

                    {force.cards === null && <p>no cards found</p>}
                    {force.cards !== null && <Table
                        columns={cardsColumns}
                        dataSource={force.cards}
                        pagination={
                          {
                            pageSize: 10
                          }
                        }
                        id={"table"}
                        style={{width: '100%'}}
                        showHeader={true}
                        bordered
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection,
                        }}
                    />}

                </Form.Item>

                <Form.Item className="forceGameCreateButton">
                    <Button onClick={navigateBack}>
                        Back
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>

            </Form>
        </>
    );
};

export default GameResultsCreate;

