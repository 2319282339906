import React, {useEffect, useState} from "react";
import {
    Button,
    Form, Input,
    Table
} from "antd";
import {useNavigate} from "react-router-dom";
import {Auth} from "../auth/Auth";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {doRestCall} from "../../AppUtils";

const UserCreate = () => {

    GoogleAnalyticsPageView("/user/create", "User Create")

    const navigate = useNavigate();

    const [form] = Form.useForm();

    useEffect(() => {
    }, [])


    const columns = [{
        title: 'Username', dataIndex: 'username', key: 'username',
    }, {
        title: 'Email', dataIndex: 'email', key: 'email',

    }];

    function onFinish(values) {
        save(values)
    }

    function onSuccess(response) {
        if (response.valid) {
            navigate('/users/all/')
        }
    }

    const save = (user) => {
        doRestCall(navigate, '/user/create', 'post', {
            username: user.username,
            password: user.password,
            email: user.email,
        }, null, onSuccess)

    }

    return (
        <>
            <Auth />
            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  style={{width: '100%'}}>

                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}>
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}>
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email">
                    <Input/>
                </Form.Item>

                <Button type="primary" htmlType="submit">
                    Submit
                </Button>

            </Form>
        </>
    );
};

export default UserCreate;

