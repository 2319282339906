import React from "react";
import {Route, Routes} from "react-router-dom";
import GameResultsCreate from "./GameResultCreate";


export function GameResultsRouter() {
    return (
        <Routes>
            <Route path="/crusade/force/:forceId/gameresults/create" element={ <GameResultsCreate /> }  />
        </Routes>
    );
}


