import React from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {doRestCall} from "../../../AppUtils";

const DeleteButton = ({ tournamentId, ownerId }) => {

    const { currentUser } = useCurrentUserContext();
    const navigate = useNavigate();

    function navigateBack() {
        navigate("/tournaments")
    }

    function onConfirmDelete() {
        doRestCall(navigate, '/tournament/delete/' + tournamentId, 'delete', null, null, navigateBack)
    }

    return <>
        {(currentUser?.role === 'ADMIN' || currentUser?.id === ownerId)
            && <Popconfirm title={"Delete tournament"}
                           description={"Are you sure you want to delete this tournament?"}
                           onConfirm={onConfirmDelete}>
                <Button>Delete</Button>
            </Popconfirm>}
    </>
}

export default DeleteButton;