import React, {createContext, useEffect, useState} from "react";
import {Button, Form, Input, Space, Row, Col, Select} from "antd";
import {useNavigate} from "react-router-dom";
import './styles.css'
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import {Auth} from "../../../user/auth/Auth";
import Forces from "./components/Forces";
import Cards from "./components/Cards";
import {notificationFailure} from "../CrusadeRosterNotifications";
import {createCrusadeRoster} from "../CrusadeRosterConnector";

const CrusadeRosterCreate = () => {

    GoogleAnalyticsPageView("/crusade/roster/create", "Crusade Roster Create")

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [summary, setSummary] = useState(0);
    const [selectedForce, setSelectedForce] = useState(undefined);

    const save = (roster) => {
        createCrusadeRoster(roster)
            .then(response => {
                    if (response.valid) {
                        navigate('/crusade/roster/view/' + response.body.id)
                    }
                    if (!response.valid) {
                        response.errors.forEach(error => notificationFailure(error.error))
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    function onFinish(values) {
        save(values)
    }

    const onForceSelected = (value) => {
        setSelectedForce(value)
        console.log("onForceSelected", value)
        form.setFieldsValue({forceId: value});
    }

    return (
        <>
            <Auth/>


            <p className="rosterCreateTitle">Create New Roster</p>

            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  style={{width: '100%'}}>

                <Row justify="space-around">
                    <Col span={10}>
                        <Form.Item name={"name"} style={{width: '100%'}}>
                            <Space direction={"horizontal"} style={{width: '100%', justifyContent: 'left'}}>
                                <p>Name</p>
                                <Input id={"name"} style={{width: '200%'}}/>
                            </Space>
                        </Form.Item>

                        <Forces onForceSelected={onForceSelected} />
                    </Col>

                    <Col span={10}>
                        <Form.Item name={"summary"} style={{width: '100%'}}>
                            <Space style={{width: '100%', justifyContent: 'right'}}>
                                <p>Summary:</p>
                                {summary}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>

                <Cards form={form}
                       setSummary={setSummary}
                       selectedForce={selectedForce} />

                <Row justify="space-around" className="rosterCreateButtons">
                    <Col span={10}></Col>

                    <Col span={10}>
                        <Space direction={"horizontal"} style={{width: '100%', justifyContent: 'right'}}>
                            <Button>Cancel</Button>
                            <Button type={"primary"} htmlType={"submit"}>Create</Button>
                        </Space>
                    </Col>
                </Row>

            </Form>

        </>
    );
};

export default CrusadeRosterCreate;

