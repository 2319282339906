import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {useNavigate} from "react-router-dom";
import {doRestCall} from "../../../AppUtils";

const Factions = ({preSelectedFaction, setSelectedFaction, selectedUniverse}) => {
    const [factionOptions, setFactionOptions] = useState([])
    const [factions, setFactions] = useState([]);
    const [defaultValue, setDefaultValue] = useState();
    const [preSelectedUniverse, setPreSelectedUniverse] = useState(selectedUniverse);

    const navigate = useNavigate();

    useEffect(() => {
        if (preSelectedUniverse === selectedUniverse && preSelectedFaction !== undefined && preSelectedFaction !== null) {
            setDefaultValue(preSelectedFaction);
            setSelectedFaction(preSelectedFaction);
        }
    }, [preSelectedFaction, factionOptions]);

    useEffect(() => {
        if (factions !== undefined && factions !== null) {
            let temp = []
            for (let i = 0; i < factions.length; i++) {
                temp[i] = {
                    value: factions[i],
                    label: factions[i],
                }
            }
            setFactionOptions(temp)
        }
    }, [factions]);

    useEffect(() => {
        setFactions(undefined)
        setDefaultValue(undefined)

        if (selectedUniverse !== undefined && selectedUniverse !== null) {
            doRestCall(navigate, '/codex/factions', 'get', {
                universe: selectedUniverse,
            }, null, (response) => setFactions(response.body) )
        }
    }, [selectedUniverse]);

    const onFactionChange = (value) => {
        setDefaultValue(value)
        setSelectedFaction(value)
        setPreSelectedUniverse(selectedUniverse)
    };

    return <>
        <Select
            value={defaultValue}
            showSearch
            placeholder="Select faction"
            onChange={onFactionChange}
            options={factionOptions}
            style={{width: 200}}
        />
    </>
}

export default Factions;