import axios from "axios";
import {getToken} from "./Auth";
import {restRoute} from "../../AppUtils";



export function validateToken() {
    return axios
        .get(restRoute() + '/auth/validate/token', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })

}
