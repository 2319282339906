import React, {useEffect, useState} from 'react';
import {Divider, Form, Select, Space, Spin, Steps} from 'antd';
import moment from "moment";
import Registration from "./Registration";

const Details = ({
    status,
    regStartDate,
    regEndDate,
    startDate,
    endDate,
    master,
    universe,
    tournamentId,
    onTournamentUpdate
}) => {
    const [tStartDateColor, setTStartDateColor] = useState('black');
    const [tEndDateColor, setTEndDateColor] = useState('black');

    const [tRegStartDateColor, setTRegStartDateColor] = useState('black');
    const [tRegEndDateColor, setTRegEndDateColor] = useState('black');

    const [tMasterColor, setTMasterColor] = useState('black');

    const [tUniverseColor, setTUniverseColor] = useState('black');

    const [tStartDate, setTStartDate] = useState(startDate);
    const [tEndDate, setTEndDate] = useState(startDate);

    const [tRegStartDate, setTRegStartDate] = useState(regStartDate);
    const [tRegEndDate, setTRegEndDate] = useState(regEndDate);

    const [tMaster, setTMaster] = useState(master);

    const [tUniverse, setTUniverse] = useState(universe);


    useEffect(() => {
        switch (status) {
            case 'REGISTRATION_CLOSED':
            case 'STARTED':
            case 'ENDED':
                setTStartDateColor("gray")
                setTEndDateColor("gray")
        }
    }, [status])

    useEffect(() => {
        if (regStartDate === undefined || regStartDate === null) {
            setTRegStartDate("<not set>")
            setTRegStartDateColor("gray")
        } else {
            setTRegStartDate(moment(regStartDate, 'YYYYMMDD').format('DD MMM YYYY'))
            setTRegStartDateColor("black")
        }

        if (regEndDate === undefined || regEndDate === null) {
            setTRegEndDate("<not set>")
            setTRegEndDateColor("gray")
        } else {
            setTRegEndDate(moment(regEndDate, 'YYYYMMDD').format('DD MMM YYYY'))
            setTRegEndDateColor("black")
        }

        if (startDate === undefined || startDate === null) {
            setTStartDate("<not set>")
            setTStartDateColor("gray")
        } else {
            setTStartDate(moment(startDate, 'YYYYMMDD').format('DD MMM YYYY'))
            setTStartDateColor("black")
        }

        if (endDate === undefined || endDate === null) {
            setTEndDate("<not set>")
            setTEndDateColor("gray")
        } else {
            setTEndDate(moment(endDate, 'YYYYMMDD').format('DD MMM YYYY'))
            setTEndDateColor("black")
        }

        if (master === undefined || master === null) {
            setTMaster("<not set>")
            setTMasterColor("gray")
        } else {
            setTMaster(master.username)
            setTMasterColor("black")
        }

        if (universe === undefined || universe === null) {
            setTUniverse("<not set>")
            setTUniverseColor("gray")
        } else {
            setTUniverse(universe)
            setTUniverseColor("black")
        }

    }, [regStartDate, regEndDate, startDate, endDate, master])

    return <>
        <Space className="tournViewDatesContainer" direction={"vertical"}>
            <Space className="tournViewDatesCenterContainer" direction={"horizontal"}>
                <Space className="tournViewDates" direction={"vertical"}>
                    <p style={{color: tStartDateColor}}>Start Date: {tStartDate}</p>
                    <p style={{color: tEndDateColor}}>End Date: {tEndDate}</p>
                </Space>
                <Space className={`${status === 'REGISTRATION_OPENED' ? "tournViewRegistrDatesBorder" : "tournViewRegistrDatesNoBorder"}`} direction={"vertical"}>
                    <p style={{color: tRegStartDateColor}}>Registration Start Date: {tRegStartDate}</p>
                    <p style={{color: tRegEndDateColor}}>Registration End Date: {tRegEndDate}</p>
                </Space>
                <Space className="tournViewRegistration" direction={"vertical"}>
                    <Registration
                        status={status}
                        tournamentId={tournamentId}
                        onTournamentUpdate={onTournamentUpdate}
                    />
                </Space>
            </Space>
            <p className="tournViewInfo" style={{color: tUniverseColor}}>Universe: {tUniverse}</p>
            <p className="tournViewInfo" style={{color: tMasterColor}}>Master: {tMaster}</p>
        </Space>
    </>
}

export default Details;