import React from "react";
import {Route, Routes} from "react-router-dom";
import UserRegistration from "./register/UserRegistration";
import UsersAll from "./all/UsersAll";
import UserCreate from "./create/UserCreate";
import UserLogin from "./login/UserLogin";
import UserLogout from "./logout/UserLogout";
import UserAccount from "./account/UserAccount";


export function UserRouter() {
    return (
        <Routes>
            <Route path="/user/registration" element={ <UserRegistration /> }  />
            <Route path="/users/all" element={ <UsersAll /> }  />
            <Route path="/user/create" element={ <UserCreate /> }  />
            <Route path="/user/login" element={ <UserLogin /> }  />
            <Route path="/user/logout" element={ <UserLogout /> }  />
            <Route path="/user/account" element={ <UserAccount /> }  />
        </Routes>
    );
}


