import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input,
    Select,
    Space,
    Table
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {allGames, allUsers, tournamentView, updateTournament} from "../TournamentConnector";
import moment from "moment";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Factions from "./components/Factions";
import Universes from "./components/Universes";
const { TextArea } = Input;

const TournamentEdit = () => {

    GoogleAnalyticsPageView("/tournament/edit", "Tournament Edit")

    const navigate = useNavigate();
    let {tournamentId} = useParams();

    const [form] = Form.useForm();

    const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState([]);

    const [gamesLoading, setGamesLoading] = useState(true)
    const [games, setGames] = useState([]);

    const [loading, setLoading] = useState(true)
    const [tournament, setTournament] = useState({});

    const [players, setPlayers] = useState([]);

    const [gameTableSelectedRowKeys, setGameTableSelectedRowKeys] = useState();
    const [userTableSelectedRowKeys, setUserTableSelectedRowKeys] = useState();

    const [selectedUniverse, setSelectedUniverse] = useState()

    const [selectedRowKeys, setRowKeys] = useState();




    form.setFieldsValue({id: tournamentId})

    useEffect(() => {
        loadUsersEffect()
        loadGamesEffect()
        loadTournament();
    }, [])

    useEffect(() => {
        form.setFieldsValue({universe: selectedUniverse});
    }, [selectedUniverse])

    useEffect(() => {
        form.setFieldsValue({
            name: tournament.name,
            createdDateTime: tournament.createdDateTime,
            status: tournament.status,
            tournamentMasterId: tournament.tournamentMasterId,
            gameIds: tournament.games && tournament.games.map(tg => tg.id),
            notes: tournament.notes
        })


        tournament.registrationStartDate && form.setFieldsValue({
            registrationStartDate: moment(tournament.registrationStartDate)
        })

        tournament.registrationEndDate && form.setFieldsValue({
            registrationEndDate: moment(tournament.registrationEndDate)
        })

        tournament.startDate && form.setFieldsValue({
            startDate: moment(tournament.startDate)
        })

        tournament.endDate && form.setFieldsValue({
            endDate: moment(tournament.endDate)
        })


        let obj = []
        tournament.players && tournament.players.map(tp => {
            obj.push({"userId": tp.id, "faction": tp.faction})
        });
        form.setFieldsValue({users: obj});


        users.forEach((user) => {
            tournament?.players?.forEach(player => {
                if (player.id === user.id) {
                    user.faction = player.faction
                }
            })

            setUsers(users);
        })

        tournament.games && setGameTableSelectedRowKeys(games.filter(value => tournament.games.map(tg => tg.id).includes(value.id)).map(value => value.key));
        tournament.players && setUserTableSelectedRowKeys(users.filter(value => tournament.players.map(tp => tp.id).includes(value.id)).map(value => value.key));


    }, [tournament, games, users])

    const loadTournament = () => {
        tournamentView(tournamentId)
            .then(response => {

                setTournament(response.body)

                //

                for (let i = 0; i < response.body.players.length; i++) {
                    response.body.players[i] = {
                        id: response.body.players[i].user.id,
                        name: response.body.players[i].user.username,
                        value: response.body.players[i].user.id,
                        label: response.body.players[i].user.username,
                        key: response.body.players[i].user.key,
                        score: response.body.players[i].score,
                        faction: response.body.players[i].faction
                    }
                }

                setPlayers(response.body.players)

                //

                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const loadUsersEffect = () => {
        allUsers()
            .then(response => {
                for (let i = 0; i < response.body.length; i++) {
                    response.body[i] = {
                        id: response.body[i].id,
                        name: response.body[i].username,
                        value: response.body[i].id,
                        label: response.body[i].username,
                        key: response.body[i].key,
                        faction: response.body[i].faction
                    }
                }
                setUsers(response.body)
                setUsersLoading(false)

            })
            .catch(error => {
                console.log(error)
            })
    }

    const loadGamesEffect = () => {
        allGames()
            .then(response => {

                for (let i = 0; i < response.body.length; i++) {
                    response.body[i] = {
                        id: response.body[i].id,
                        name: response.body[i].name,
                        value: response.body[i].id,
                        label: response.body[i].name,
                        key: response.body[i].key
                    }
                }

                setGames(response.body)
                setGamesLoading(false)

            })
            .catch(error => {
                console.log(error)
            })
    }

    const save = (tournament) => {

        updateTournament(tournament)
            .then(response => {
                    if (response.valid) {
                        navigate('/tournament/view/' + response.body.id)
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    function onFinish(values) {
        save(values)
    }

    const usersTableColumns = [{
        title: 'Name', dataIndex: 'label', key: 'key', width: '60%',
    }, {
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '40%',
        render: (_, row) => (<>
            <Factions selectedUniverse={selectedUniverse} preSelectedFaction={row.faction} setSelectedFaction={(value) => {
                row.faction = value
                let fieldValue = form.getFieldValue("users");

                fieldValue && fieldValue.forEach((field) => {
                    if (field.userId === row.id) {
                        field.faction = row.faction
                    }
                })
            }}
            />
            </>
        )
    }
    ];

    const gamesTableColumns = [{
        title: 'Name', dataIndex: 'name', key: 'key', width: '50%',
    },{
        title: 'Winner', dataIndex: 'winner', key: 'key', width: '25%',
    },{
        title: 'Date', dataIndex: 'date', key: 'date', width: '25%',
    },
    ];

    const gamesRowSelection = {
        selectedRowKeys: gameTableSelectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {

            if (selectedRowKeys.length === 0) {
                form.setFieldsValue({gameIds: []});
            } else {
                form.setFieldsValue({gameIds: selectedRows.map(row => row.id)});
            }

            setGameTableSelectedRowKeys(selectedRowKeys);

        },
    };

    const usersRowSelection = {
        selectedRowKeys: userTableSelectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRowKeys.length === 0) {
                form.setFieldsValue({users: []});
            } else {
                let obj = []
                selectedRows.map((row, index) => {
                    obj.push({"userId": row.id, "faction": row.faction})
                });
                form.setFieldsValue({users: obj});
            }

            setUserTableSelectedRowKeys(selectedRowKeys);
        },
    };

    function onTournamentMasterSelect() {

    }

    function navigateBack() {
        navigate("/tournament/view/" + tournamentId)
    }
    return (
        <>
            <Auth/>
            <h2 className="tournEditTitle">Edit Tournament {tournament.name}</h2>
            <Form
                id={"form"}
                onFinish={onFinish}
                form={form}
            >

                <Space className="tournEditForm" direction={"vertical"}>
                    <Form.Item
                        className="tournEditLabel"
                        name="id"
                        hidden="true"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        className="tournEditLabel"
                        name="status"
                        hidden="true"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        className="tournEditLabel"
                        name="createdDateTime"
                        hidden="true"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        className="tournEditLabel"
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input name!',
                            },
                        ]}>
                        <Input className="tournEditInput"/>
                    </Form.Item>

                    <Space direction={"horizontal"}>
                        <Space className="tournEditDatesLeft" direction={"vertical"}>
                            <Form.Item
                                className="tournEditLabelLong"
                                label="Start Date"
                                name="startDate">

                                <DatePicker className="tournEditDate"/>
                            </Form.Item>

                            <Form.Item
                                className="tournEditLabelLong tournEditNoMarginBottom"
                                label="End Date"
                                name="endDate">

                                <DatePicker className="tournEditDate"/>
                            </Form.Item>

                        </Space>
                        <Space className="tournEditDatesRight" direction={"vertical"}>
                            <Form.Item

                                label="Registration Start Date"
                                name="registrationStartDate">
                                <DatePicker className="tournEditDate"/>
                            </Form.Item>

                            <Form.Item

                                label="Registration End Date"
                                name="registrationEndDate">
                                <DatePicker className="tournEditDate"/>
                            </Form.Item>
                        </Space>
                    </Space>

                    <Form.Item
                        className="tournEditLabel"
                        label="Universe"
                        name="universe"
                        style={{width: '10.3rem', justifyContent: 'start', display: 'flex'}}
                    >
                        <Universes setSelectedUniverse={setSelectedUniverse} preSelectedUniverse={tournament.universe}   />
                    </Form.Item>

                    <Form.Item
                        className="tournEditLabel"
                        label="Tournament Master"
                        name="tournamentMasterId">
                        <Select options={users} onSelect={onTournamentMasterSelect} className="tournEditSelector"/>
                    </Form.Item>

                    <Form.Item
                        className="tournEditLabel"
                        label="Notes" name="notes" >
                        <TextArea rows={6} maxLength={1000} />
                    </Form.Item>
                </Space>

                <Form.Item name="gameIds" hidden={true} />
                <Form.Item name="userIds" hidden={true} />
                <Form.Item name="users" hidden={true} />

                {(usersLoading || users.length === 0) &&
                    <>
                        <h2 className="tournEditTableTitles">Participants:</h2>
                        <div className="tournEditNoTable"> No Participants Found</div>
                    </>
                }
                {(!usersLoading && users.length > 0) &&
                    <>
                        <h2 className="tournEditTableTitles">Participants:</h2>
                        <Form.Item name="usersFormItem">
                            <Table
                                columns={usersTableColumns}
                                dataSource={users}
                                pagination={
                                    {
                                        pageSize: 10
                                    }
                                }
                                id={"usersTable"}
                                style={{width: '100%'}}
                                bordered
                                showHeader={true}
                                rowSelection={{
                                    type: 'checkbox', ...usersRowSelection,
                                }}
                                className="tournEditTable"
                            />
                        </Form.Item>
                    </>
                }

                {(gamesLoading || games.length === 0) &&
                    <>
                        <h2 className="tournEditTableTitles">Games:</h2>
                        <div className="tournEditNoTable"> No Games Found</div>
                    </>
                }
                {(!gamesLoading && games.length > 0) &&
                    <>
                        <h2 className="tournEditTableTitles">Games:</h2>
                        <Form.Item name="gamesFormItem">
                            <Table
                                columns={gamesTableColumns}
                                dataSource={games}
                                pagination={
                                    {
                                        pageSize: 10
                                    }
                                }
                                id={"gamesTable"}
                                style={{width: '100%'}}
                                bordered
                                showHeader={true}
                                rowSelection={{
                                    type: 'checkbox', ...gamesRowSelection,
                                }}
                                className="tournEditTable"
                            />
                        </Form.Item>
                    </>
                }

                <Form.Item>
                    <Space className="tournEditButtons" direction="horizontal">
                        <Button onClick={navigateBack}>Back</Button>
                        <Button type="primary" htmlType="submit" data-cy="submit" >
                            Update
                        </Button>
                    </Space>
                </Form.Item>

            </Form>
        </>
    );
};

export default TournamentEdit;

