import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, InputNumber, Select, Space, Table} from "antd";
import {usersAll} from "../../../user/UserConnector";
import moment from "moment/moment";
import Factions from "./Factions";


const Participants = ({form, participants, selectedUniverse}) => {

    const [players, setPlayers] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [selectedPlayerId, setSelectedPlayerId] = useState([]);

    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);


    useEffect(() => {
        usersAll()
            .then(response => {
                for (let i = 0; i < response.body.length; i++) {
                    response.body[i] = {
                        id: response.body[i].id,
                        key: response.body[i].key,
                        name: response.body[i].username,
                        value: response.body[i].id,
                        label: response.body[i].username,
                    }
                }

                setPlayers(response.body)
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    useEffect(() => {
        let obj = []
        selectedPlayers && selectedPlayers.map(player => {
            obj.push({
                "userId": player.id,
                "winner": player.winner,
                "score": player.score,
                "faction": player.faction
            })
        });

        form.setFieldsValue({
            players: obj
        })
    }, [selectedPlayers])

    useEffect(() => {


        participants && participants.forEach(participant => {
            participant.name = participant.username
            participant.id = participant.userId
        })

        setSelectedPlayers(participants)

    }, [participants])


    const handleDelete = (key) => {
        const newData = selectedPlayers.filter((item) => item.key !== key);
        setSelectedPlayers(newData);
    };

    const handleAdd = () => {
        const newData = players.filter(player => player.id === selectedPlayerId)[0]
        setSelectedPlayers([...selectedPlayers, newData]);
    };

    const onPlayerSelected = (selectedPlayerId) => {
        setSelectedPlayerId(selectedPlayerId);
        setIsAddButtonDisabled(selectedPlayerId === undefined);
    };

    const onWinnerChange = (row, winner, isChecked) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.winner = isChecked;

        setSelectedPlayers([...selectedPlayers])
    }

    const onFactionChange = (row, faction) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.faction = faction;

        setSelectedPlayers([...selectedPlayers])
    }

    const onScoreChanged = (row, score) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.score = score;

        setSelectedPlayers([...selectedPlayers])
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction',
        render: (faction, row) => (
            <Factions selectedUniverse={selectedUniverse} preSelectedFaction={faction} setSelectedFaction={(faction) => onFactionChange(row, faction)} />
        )
    },{
        title: 'Score', dataIndex: 'score', key: 'score',
        render: (score, row) => (
            <InputNumber value={score} min={0} onChange={(value) => onScoreChanged(row, value)} />
        )
    },{
        title: 'Winner', dataIndex: 'winner', key: 'winner',
        render: (winner, row) => (
            <Checkbox defaultChecked={winner} onChange={(e) => onWinnerChange(row, winner, e.target.checked)} />
        )
    },{
        title: 'Actions', dataIndex: 'actions', key: 'actions',
        render: (_, record) => (
            <Space direction="horizontal"><Button onClick={() => handleDelete(record.key)}>Delete</Button></Space>
        )
    }];

    return <>
        <Form.Item name="players" />

        <h4 className="participantsEditHeader">Participants</h4>

        <Space className="gameEditSelectParticipant" direction={"horizontal"}>
            <Select
                options={players}
                onChange={onPlayerSelected}
                allowClear={true}
                className="gameEditSelector"
            />
            <Button disabled={isAddButtonDisabled} onClick={handleAdd}>Add</Button>
        </Space>

        <Table
            bordered
            className="participantsEditTable"
            dataSource={selectedPlayers}
            columns={columns}
        />

    </>
}

export default Participants;