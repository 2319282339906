import React from "react";
import {Route, Routes} from "react-router-dom";
import GameCreate from "./create/GameCreate";
import MyGames from "./my/MyGames";
import GameView from "./view/GameView";
import GameEdit from "./edit/GameEdit";
import Games from "./all/Games";


export function GameRouter() {
    return (
        <Routes>
            <Route path="/game/create" element={ <GameCreate /> }  />
            <Route path="/games" element={ <Games /> }  />
            <Route path="/game/view/:gameId" element={ <GameView /> }  />
            <Route path="/game/edit/:gameId" element={ <GameEdit /> }  />
        </Routes>
    );
}


