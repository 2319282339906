import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {gameView} from "../../game/GameConnector";
import {tournamentView} from "../TournamentConnector";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Popover, Space, Table} from "antd";
import moment from "moment/moment";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import TournamentStarted from "./TournamentStarted";
import Factions from "../create/components/Factions";
import {doRestCall} from "../../AppUtils";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";

const TournamentCreated = ({tournament}) => {

    GoogleAnalyticsPageView("/tournament/view", "Tournament View")

    const navigate = useNavigate();

    const { currentUser } = useCurrentUserContext();

    const [loading, setLoading] = useState(true)

    function navigateBack() {
        navigate("/tournaments")
    }

    const usersTableColumns = [{
        title: 'Name', dataIndex: 'username', key: 'key', width: '60%',
        render: (_, row) => (row.user?.username)
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '40%',
    }];

    return (
        <>
            <Auth/>

            <Space className="tournTableInfo" direction={"vertical"} >
            { ( tournament?.players?.length === 0) &&
                <>
                    <h2 className="tournTableInfoTitles">Participants:</h2>
                    <div className="tournViewNoTable"> No Participants Found </div>
                </>
            }
            { ( tournament?.players?.length > 0) && (
                <>
                    <h2 className="tournTableInfoTitles">Participants:</h2>
                    <div className="tournViewTable">
                        <Table
                            columns={usersTableColumns}
                            dataSource={tournament.players}
                            pagination={
                                {
                                    pageSize: 10
                                }
                            }
                            id={"usersTable"}
                            style={{width: '100%', minWidth: '42rem'}}
                            showHeader={true}
                        />
                    </div>
                </>
            )}

            { (currentUser?.role === 'ADMIN' || currentUser?.id === tournament.ownerId || currentUser?.tournamentMasterId)
                && (
                    <div className="tournViewButtons">
                        <Button  onClick={navigateBack}>Back</Button>
                        <div>
                            <Space direction={"horizontal"}>
                                <EditButton tournamentId={tournament.id} ownerId={tournament.id} tournamentMasterId={tournament.tournamentMasterId}  />
                                <DeleteButton tournamentId={tournament.id} ownerId={tournament.ownerId} />
                            </Space>
                        </div>
                    </div>
                )
            }
            </Space>
        </>
    );
};

export default TournamentCreated;

