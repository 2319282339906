import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {codexFactions, loadUnit} from "../../CardConnector";
import {loadUnitsByFaction} from "../../../../codex/CodexConnector";
import Unit from "./Unit";

const Units = ({selectedFaction, selectedUnit, onUnitUpdate}) => {
    const [units, setUnits] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [unit, setUnit] = useState(selectedUnit);

    useEffect(() => {

        if (units !== undefined && units !== null) {
            let temp = []
            for (let i = 0; i < units.length; i++) {
                temp[i] = {
                    value: units[i].name,
                    label: units[i].name,
                }
            }
            setUnitOptions(temp)
        }

    }, [units]);

    useEffect(() => {
        if (selectedFaction !== undefined) {
            loadUnitsByFaction(selectedFaction)
                .then(response => {
                    setUnits(response.body)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [selectedFaction])

    useEffect(() => {
        if (unit !== undefined && unit !== null) {
            console.log("useEffect unit", unit)
            onUnitUpdate(unit)
        }
    },[unit])

    useEffect(() => {
        if (selectedUnit !== undefined && selectedUnit !== null) {
            console.log("useEffect unit", unit)
            setUnit(selectedUnit)
        }
    },[selectedUnit])

    const onUnitSelected = (unitName) => {
        loadUnit(selectedFaction, unitName)
            .then(response => {
                setUnit(response.body)
            })
    }

    const onUnitsChange = () => {
        if (unit !== undefined && unit !== null) {
            console.log("onUnitsChange")
            onUnitUpdate(unit)
        }
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <>
         <Select
                showSearch
                value={selectedUnit?.name}
                defaultValue={selectedUnit?.name}
                placeholder="Select a unit"
                filterOption={filterOption}
                onSelect={onUnitSelected}
                options={unitOptions}
                style={{width: 400}}
            />

        <Form.Item name={"unit"}>
            {(unit !== undefined && unit.models !== undefined) && <Unit unit={unit} onUnitsChange={onUnitsChange}/>}
        </Form.Item>
    </>
}

export default Units;