import React from "react";
import {Route, Routes} from "react-router-dom";
import RestUsage from "./restusage/RestUsage";
import Health from "./Health";


export function SystemRouter() {
    return (
        <Routes>
            <Route path="/system/usage/rest" element={ <RestUsage /> }  />
            <Route path="/system/health" element={ <Health /> }  />
        </Routes>
    );
}


