import React, {useEffect, useState} from "react";
import {Space, Typography} from 'antd';
import "../styles.css"
import CardInfo from './CardInfo';

import Warlord from "./Warlord";
import Prices from "./Prices";
import Leader from "./Leader";

const {Text, Link} = Typography;

const Card = ({card, onLeaderSelected, data, setData, onWarlordSelected}) => {

    const [hideCustomize, setHideCustomize] = useState(true);
    const [isWarlord, setWarlord] = useState(false)

    useEffect(() => {
        setWarlord(card.unit.warlord);
    }, [])

    useEffect(() => {

        card.unit.warlord = isWarlord
        console.log("Card useEffect isWarlord", isWarlord, card.unit.warlord)

        onWarlordSelected()
    }, [isWarlord])

    return (
        <Space direction="horizontal"
                           onPointerEnter={() => setHideCustomize(false)}
                           onPointerLeave={() => setHideCustomize(true)} >

            {isWarlord && <Text code>WARLORD</Text>}

                <Space direction="vertical">
                    <Text strong className="rosterCreateParentName">{card.name}</Text>
                    <Text type="secondary" style={{color: "silver"}}>{card?.unit?.name}</Text>
                    <Text type="secondary" style={{color: "silver"}}>{card.unit?.models
                        .flatMap(model => model.weapons)
                        .map(weapon => weapon.name)
                        .join(", ")}</Text>
                </Space>
                <Space direction="horizontal">
                    <Prices price={card.points}/>
                </Space>
                <Space direction="horizontal">
                    <CardInfo card={card}/>
                </Space>
                <Space direction="horizontal">
                    <Warlord hideCustomize={hideCustomize} unit={card.unit} setWarlord={setWarlord}/>
                    <Leader unit={card.unit} onLeaderSelected={onLeaderSelected}/>
                </Space>
            </Space>
    );
};

export default Card;













