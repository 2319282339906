import {message, notification} from "antd";

export function notificationFailure() {
    message.error({
        duration: 2,
        content: 'Something is wrong',
    });
}

export function notificationCreateFailure() {
    message.error({
        duration: 2,
        content: 'Cant create force',
    });
}

export function notificationCreateForceSuccess() {
    message.success({
        duration: 2,
        content: 'Force created',
    });
}

export function notificationSupplyLimitUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Supply Limit updated',
    });
}

export function notificationSupplyUsedUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Supply Used updated',
    });
}

export function notificationBattleTallyUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Battle Tally updated',
    });
}

export function notificationVictoriesUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Victories updated',
    });
}

export function notificationReqPointsUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Req Points updated',
    });
}

export function notificationNameUpdateSuccess() {
    message.success({
        duration: 2,
        content: 'Name updated',
    });
}
