import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {codexFactions} from "../../CardConnector";

const Factions = ({setSelectedFaction, onFactionUpdate}) => {
    const [factionOptions, setFactionOptions] = useState([])
    const [factions, setFactions] = useState([]);

    useEffect(() => {
        if (factions !== undefined && factions !== null) {
            let temp = []
            for (let i = 0; i < factions.length; i++) {
                temp[i] = {
                    value: factions[i],
                    label: factions[i],
                }
            }
            setFactionOptions(temp)
        }
    }, [factions]);

    useEffect(() => {
        codexFactions()
            .then(response => {
                    setFactions(response.body)
                }
            )
            .catch(error => {
                console.log(error)
            })
    }, []);

    const onFactionChange = (value) => {
        setSelectedFaction(value)
        onFactionUpdate(value)
    };

    return <>
        <Form.Item name="faction">
            <Select
                showSearch
                placeholder="Select faction"
                onChange={onFactionChange}
                options={factionOptions}
                style={{width: 200}}
            />
        </Form.Item>
    </>
}

export default Factions;