import axios from "axios";
import {getToken} from "../../user/auth/Auth";
import {restRoute} from "../../AppUtils";


export function forceView(forceId) {
    return axios
        .get(restRoute() + '/crusade/force/' + forceId + '/view', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function gameResultView(forceId, gameResultId) {
    return axios
        .get(restRoute() + '/crusade/force/' + forceId + '/gameresult/' + gameResultId + '/view', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}


export function createGameResult(gameresult) {
    return axios
        .post(restRoute() + '/crusade/gameresult/create', gameresult, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}





