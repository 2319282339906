import React from "react";
import {Route, Routes} from "react-router-dom";
import CardCreate from "./create/CardCreate";
import CardManage from "./manage/CardManage";


export function CardRouter() {
    return (
        <Routes>
            <Route path="/crusade/card/create" element={ <CardCreate /> }  />
            <Route path="/crusade/force/:forceId/card/:cardId/manage" element={ <CardManage /> }  />
        </Routes>
    );
}


