import './App.css';

import {BrowserRouter, Route, Router} from "react-router-dom";
import {RosterRouter} from "./roster/RosterRouter";
import {GameRouter} from "./game/GameRouter";
import {HomeRouter} from "./home/HomeRouter";
import MainMenu from "./menu/MainMenu";
import {UserRouter} from "./user/UserRouter";
import {TournamentRouter} from "./tournament/TournamentRouter";
import {CardRouter} from "./crusade/card/CardRouter";
import {ForceRouter} from "./crusade/force/ForceRouter";
import {GameResultsRouter} from "./crusade/gameresults/GameResultsRouter";
import {SystemRouter} from "./system/SystemRouter";
import {CurrentUserContextProvider, useCurrentUserContext} from "./user/CurrentUserContext";
import ReactGA from "react-ga4";
import {TagRouter} from "./tag/TagRouter";
import {CodexRouter} from "./codex/CodexRouter";
import {CrusadeRosterRouter} from "./crusade/roster/CrusadeRosterRouter";
import Footer from "./home/Footer";
import React from "react";

function App() {

    ReactGA.initialize("G-GG4M498TS6");

    const queryParams = new URLSearchParams(window.location.search)
    const ref = queryParams.get("ref")

    return (
        <div className="App">
            <BrowserRouter>
                <CurrentUserContextProvider>

                    <MainMenu/>
                    <SystemRouter/>

                    <HomeRouter/>
                    <RosterRouter/>
                    <GameRouter/>
                    <UserRouter/>
                    <TournamentRouter/>
                    <TagRouter />
                    <CodexRouter />

                    {/*  CRUSADE  */}

                    <CardRouter/>
                    <ForceRouter/>
                    <GameResultsRouter/>
                    <CrusadeRosterRouter />

                    <Footer />

                </CurrentUserContextProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
