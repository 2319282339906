import React from 'react';
import {Spin, Typography} from 'antd';
import { v4 as uuidv4 } from 'uuid';

const Prices = ({prices}) => {

    const {Text, Link} = Typography;

    return <>
        <Text type="secondary" style={{color: "silver"}} className="rosterCreateRowSpacing" key={uuidv4()} >
            Price: {Object.entries(prices).map(([key, value]) => (
            <>{key}x{value}</>
        )).reduce((prev, curr) => [prev, ', ', curr])}
        </Text>
    </>
}

export default Prices;