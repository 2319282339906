import React, {useEffect, useState} from "react";
import {
    Button,
    Space,
    Table
} from "antd";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import './styles.css'
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {doRestCall} from "../../AppUtils";

const Games = () => {

    GoogleAnalyticsPageView("/games/my", "Games My")

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [games, setGames] = useState({});

    const [created, setCreated] = useState(false);
    const [master, setMaster] = useState(false);
    const [participated, setParticipated] = useState(false);
    const [all, setAll] = useState(true);


    function onSuccess(response) {
        setGames(response.body)
        setLoading(false)
    }

    useEffect(() => {
        doRestCall(navigate, '/games', 'get', {
            created: created,
            master: master,
            participated: participated,
            all: all
        }, null, onSuccess )
    }, [created, master, participated, all])

    function view(id) {
        navigate('/game/view/' + id)
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
        render: (name, row) => (
            <Button type="link" onClick={() => view(row.id)}>{name}</Button>
        )
    },{
        title: 'Master', dataIndex: 'master', key: 'master',
        render: (master, row) => (
            <p>{row.gameMaster && row.gameMaster.username}</p>
        )
    }, {
        title: 'Date', dataIndex: 'dateTime', key: 'dateTime',
        render: (dateTime, row) => (
            <p>{dateTime && moment(dateTime).format('DD MMM YYYY')}</p>
        )
    }];

    function onCreated() {
        setCreated(!created)
    }

    function onMaster() {
        setMaster(!master)
    }

    function onParticipated() {
        setParticipated(!participated)
    }

    function onAll() {
        setAll(!all)
    }

    return (
        <>
            <Auth />
            {loading && <p>loading</p>}
            {!loading &&
              <>
                <h2 className="gameListHeader">Games</h2>

                    <Space className="gameListButtons" direction={"horizontal"}>
                        <Space.Compact block>
                            <Button onClick={onCreated} className={created ? "onGamesButton" : "offGamesButton"}>
                                Created by me
                            </Button>
                            <Button onClick={onMaster} className={master ? "onGamesButton" : "offGamesButton"}>
                                Im master
                            </Button>
                            <Button onClick={onParticipated} className={participated ? "onGamesButton" : "offGamesButton"}>
                                Im participated
                            </Button>
                            <Button onClick={onAll} className={all ? "onGamesButton" : "offGamesButton"}>
                                All
                            </Button>
                        </Space.Compact>
                        <Button data-cy="createGame" onClick={() => navigate("/game/create")}>Create Game</Button>
                    </Space>

                  <Table
                    columns={columns}
                    dataSource={games}
                    pagination={true}
                    id={"table"}
                    style={{width: '100%'}}
                    showHeader={true}
                    className="gameList"
                />
              </>
            }
        </>
    );
};

export default Games;

