import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {codexFactions, loadUnit} from "../../CardConnector";
import {loadUnitsByFaction} from "../../../../codex/CodexConnector";
import Unit from "./Unit";

const Units = ({form, selectedFaction, setSelectedUnit, onUnitsChange}) => {
    const [units, setUnits] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [unit, setUnit] = useState();

    useEffect(() => {

        if (units !== undefined && units !== null) {
            let temp = []
            for (let i = 0; i < units.length; i++) {
                temp[i] = {
                    value: units[i].name,
                    label: units[i].name,
                }
            }
            setUnitOptions(temp)
        }

    }, [units]);


    useEffect(() => {

        loadUnitsByFaction(selectedFaction)
            .then(response => {
                setUnits(response.body)
            })
            .catch(error => {
                console.log(error)
            })

    }, [selectedFaction])

    const onChange = (unitName) => {
        loadUnit(selectedFaction, unitName)
            .then(response => {
                setUnit(response.body)
                setSelectedUnit(response.body)
                form.setFieldsValue({
                    unit: response.body
                })
            })
    };

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <>
        <Select
            data-cy="unit"
            showSearch
            placeholder="Select a unit"
            filterOption={filterOption}
            options={unitOptions}
            style={{width: 400}}
            onChange={onChange}
        />

        <Form.Item name={"unit"}>
            {(unit !== undefined && unit.models !== undefined) && <Unit unit={unit} onUnitsChange={onUnitsChange}  />}
        </Form.Item>
    </>
}

export default Units;