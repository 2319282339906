import React from "react";
import {Route, Routes} from "react-router-dom";
import RosterCreate from "./create/RosterCreate";
import RostersAll from "./all/RostersAll";
import RosterView from "./view/RosterView";
import RosterEdit from "./edit/RosterEdit";
import RostersMy from "./my/RostersMy";



export function RosterRouter() {
    return (
        <Routes>
            <Route path="/roster/create" element={<RosterCreate/>} />
            <Route path="/roster/edit/:rosterId" element={<RosterEdit/>} />
            <Route path="/rosters/all" element={<RostersAll/>} />
            <Route path="/rosters/my" element={<RostersMy/>} />
            <Route path="/roster/view/:rosterId" element={<RosterView/>} />
        </Routes>
    );
}

