import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import TextArea from "antd/es/input/TextArea";

const Keywords = ({form, selectedFaction, selectedUnit}) => {

    const [keywords, setKeywords] = useState([]);


    useEffect(() => {

            setKeywords(selectedUnit?.keywords)

            form.setFieldsValue({keywords: selectedUnit?.keywords?.join(", ")});

    }, [selectedUnit])

    return <>
        <Form.Item
            label="Keywords"
            name="keywords">
            <TextArea className="forceCardCreateInput" />
        </Form.Item>
    </>
}

export default Keywords;