import React from "react";
import {FacebookOutlined, MailOutlined, TeamOutlined, DollarOutlined, GoogleOutlined, DiscordOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {Affix, Button, Space, Typography} from "antd";
import {useCurrentUserContext} from "../user/CurrentUserContext";

const { Text } = Typography;

const Footer = () => {
    const navigate = useNavigate();
    const { currentUser } = useCurrentUserContext();

    function openLink(link) {
        window.open(link, '_blank').focus();
    }

    return (
        <>
            <Affix offsetBottom={0} >
                <Space style={{backgroundColor: "white", width: '100%'}} align="center" direction="horizontal" >
                    <Button type="text"
                            onClick={() => openLink('https://gofund.me/e0aa6bc5')}><DollarOutlined /></Button>
                    <Button type="text"
                            onClick={() => openLink('https://www.facebook.com/groups/824327146381514')}><FacebookOutlined/></Button>
                    <Button type="text"
                            onClick={() => openLink('https://groups.google.com/g/smartroster')}><GoogleOutlined /></Button>
                    <Button type="text"
                            onClick={() => openLink('https://discord.gg/hEUbeTvhZn')}><DiscordOutlined /></Button>
                    <Button type="text"
                            onClick={() => window.location.href = "mailto:modelsontheshelf@gmail.com"}><MailOutlined/></Button>
                    <Text disabled>Env: {process.env.REACT_APP_ENV}</Text>
                    <Text disabled>Build #: {process.env.REACT_APP_BUILD}</Text>
                    <Text disabled>Revision: {process.env.REACT_APP_REVISION}</Text>
                    {currentUser && <Text disabled>User: {currentUser && currentUser.username}</Text>}
                </Space>
            </Affix>
        </>
    );
};

export default Footer;

