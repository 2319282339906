import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {codexFactions} from "../../GameConnector";
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";

const Factions = ({setSelectedFaction, selectedUniverse}) => {
    const [factionOptions, setFactionOptions] = useState([])
    const [factions, setFactions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (factions !== undefined && factions !== null) {
            let temp = []
            for (let i = 0; i < factions.length; i++) {
                temp[i] = {
                    value: factions[i],
                    label: factions[i],
                }
            }
            setFactionOptions(temp)
        }
    }, [factions]);

    useEffect(() => {
        setFactions([])
        setSelectedValue(undefined)

        if (selectedUniverse !== undefined && selectedUniverse !== null) {
            doRestCall(navigate, '/codex/factions', 'get', {
                universe: selectedUniverse,
            }, null, (response) => setFactions(response.body))
        }
    }, [selectedUniverse]);

    const onFactionChange = (value) => {
        setSelectedFaction(value)
        setSelectedValue(value)
    };

    return <>
            <Select
                showSearch
                placeholder="Select faction"
                onChange={onFactionChange}
                options={factionOptions}
                style={{width: 200}}
                value={selectedValue}
            />
    </>
}

export default Factions;