import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {gameView} from "../../game/GameConnector";
import {isRegistered, register, tournamentView, unregister} from "../TournamentConnector";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Popover, Select, Space, Table} from "antd";
import moment from "moment/moment";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import TournamentStarted from "./TournamentStarted";
import Factions from "./components/Factions";
import {doRestCall} from "../../AppUtils";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";

const TournamentRegistrationOpen = ({tournament}) => {

    GoogleAnalyticsPageView("/tournament/view", "Tournament View")

    const navigate = useNavigate();

    const { currentUser } = useCurrentUserContext();
    const [players, setPlayers] = useState([]);


    useEffect(() => {
        if (tournament.players !== undefined && tournament.players !== null) {
            let players = []

            for (let i = 0; i <tournament.players.length; i++) {
                players[i] = {
                    id: tournament.players[i].user.id,
                    name: tournament.players[i].user.username,
                    value: tournament.players[i].user.id,
                    label: tournament.players[i].user.username,
                    key: tournament.players[i].user.key,
                    faction: tournament.players[i].faction,
                }
            }
            setPlayers(players)
        }
    }, [tournament]);

    function onConfirmDelete() {
        doRestCall(navigate, '/tournament/delete/' + tournament.id, 'delete', null, null, navigateBack)
    }

    const onEdit = () => {
        navigate("/tournament/edit/" + tournament.id)
    };

    const usersTableColumns = [{
        title: 'Name', dataIndex: 'label', key: 'key', width: '60%',
    }, {
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '40%',
    }];

    function navigateBack() {
        navigate("/tournaments")
    }

    return (
        <>
            <Auth/>

            <Space className="tournTableInfo" direction={"vertical"} >
            {(players.length === 0) &&
                <>
                    <h2 className="tournTableInfoTitles">Participants:</h2>
                    <div className="tournViewNoTable"> No Participants Found</div>
                </>
            }
            {(players.length > 0) && (
                <>
                    <h2 className="tournTableInfoTitles">Participants:</h2>
                    <div className="tournViewTable">
                        <Table
                            columns={usersTableColumns}
                            dataSource={players}
                            pagination={
                                {
                                    pageSize: 10
                                }
                            }
                            id={"usersTable"}
                            style={{width: '100%', minWidth: '42rem'}}
                            showHeader={true}
                        />
                    </div>
                </>
            )}

            { currentUser && (currentUser.role === 'ADMIN' || currentUser.id === tournament.ownerId || currentUser.tournamentMasterId)
                && (
                    <div className="tournViewButtons">
                        <Button  onClick={navigateBack}>Back</Button>
                        <Space direction={"horizontal"}>
                            <EditButton tournamentId={tournament.id} ownerId={tournament.id} tournamentMasterId={tournament.tournamentMasterId}  />
                            <DeleteButton tournamentId={tournament.id} ownerId={tournament.ownerId} />
                        </Space>
                    </div>
                )
            }

            </Space>
        </>
    );
};

export default TournamentRegistrationOpen;

