import React from "react";

const CardPrint = ({card}) => {
    return <>
        {card.name}
    </>
};

export default CardPrint;

