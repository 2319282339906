import React, {useEffect, useMemo, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input, InputNumber, notification,
    Select,
    Table
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import "./styles.css"

import {
    calculateUnitPrice,
    updateBattleHonors,
    updateBattleScars,
    updateBattlesPlayed,
    updateBattlesSurvived,
    updateCrusadePoints,
    updateEnemyUnitsDestroyed,
    updateEnhancements,
    updateEquipment,
    updateExperiencePoints, updateFaction,
    updateKeywords,
    updateName,
    updatePoints, updateRank,
    updateType, updateUnit, updateUnitName,
    viewCard
} from "../CardConnector";
import {Auth} from "../../../user/auth/Auth";
import TextArea from "antd/es/input/TextArea";
import {
    notificationBattleHonorsUpdateSuccess,
    notificationBattleScarsUpdateSuccess,
    notificationBattlesPlayedUpdateSuccess,
    notificationBattlesSurvivedUpdateSuccess,
    notificationCrusadePointsUpdateSuccess,
    notificationEnemyUnitsDestroyedUpdateSuccess,
    notificationEnhancementsUpdateSuccess,
    notificationEquipmentsUpdateSuccess,
    notificationExperiencePointsUpdateSuccess,
    notificationFactionUpdateSuccess,
    notificationFailure,
    notificationKeywordsUpdateSuccess,
    notificationNameUpdateSuccess,
    notificationPointsUpdateSuccess,
    notificationRankUpdateSuccess,
    notificationTypeUpdateSuccess, notificationUnitNameUpdateSuccess, notificationUnitUpdateSuccess
} from "../CardNotifications";
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import Factions from "./components/Factions";
import Units from "./components/Units";


const CardManage = () => {

    GoogleAnalyticsPageView("/crusade/card/manage", "Crusade Card Manage")

    const navigate = useNavigate();

    const [form] = Form.useForm();

    let {forceId, cardId} = useParams();

    const [card, setCard] = useState();
    const [selectedFaction, setSelectedFaction] = useState();

    useEffect(() => {
        load()
    }, []);

    useEffect(() => {
        if (card !== undefined) {
            setSelectedFaction(card.faction)
            form.setFieldsValue({
                name: card.name,
                faction: card.faction,
                unit: card.unit,
                keywords: card.keywords,
                equipments: card.equipments,
                enhancements: card.enhancements,
                points: card.points,
                crusadePoints: card.crusadePoints,
                experiencePoints: card.experiencePoints,
                rank: card.rank,
                battlesPlayed: card.battlesPlayed,
                battlesSurvived: card.battlesSurvived,
                enemyUnitsDestroyed: card.enemyUnitsDestroyed,
                battleHonors: card.battleHonors,
                battleScars: card.battleScars
            })
        }
    }, [card])

    const load = () => {
        viewCard(forceId, cardId)
            .then(response => {
                    if (response.valid) {
                        setCard(response.body)
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    const calculatePoints = (unit) => {
        calculateUnitPrice(unit)
            .then(response => {
                    if (response.valid) {
                        card.points = response.body
                        form.setFieldsValue({
                            points: card.points
                        })
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    function onFinish(values) {
        // save(values)
    }

    function navigateBack(values) {
        console.log('Success:', values);
        navigate("/crusade/force/" + forceId + "/manage")
    }

    const onNameUpdate = (e) => {
        console.log("onNameUpdate", e.target.value)
        updateName(forceId, cardId, e.target.value, notificationNameUpdateSuccess, notificationFailure)
    }
    const onUnitUpdate = (value) => {
        updateUnit(forceId, cardId, value, notificationUnitUpdateSuccess, notificationFailure)
        calculatePoints(value)
    }
    const onFactionUpdate = (value) => {
        updateFaction(forceId, cardId, value, notificationFactionUpdateSuccess, notificationFailure)
    }
    const onKeywordsUpdate = (e) => {
        updateKeywords(forceId, cardId, e.target.value, notificationKeywordsUpdateSuccess, notificationFailure)
    }
    const onEquipmentsUpdate = (e) => {
        updateEquipment(forceId, cardId, e.target.value, notificationEquipmentsUpdateSuccess, notificationFailure)
    }
    const onEnhancementsUpdate = (e) => {
        updateEnhancements(forceId, cardId, e.target.value, notificationEnhancementsUpdateSuccess, notificationFailure)
    }
    const onPointsUpdate = (e) => {
        updatePoints(forceId, cardId, e.target.value, notificationPointsUpdateSuccess, notificationFailure)
    }
    const onCrusadePointsUpdate = (e) => {
        updateCrusadePoints(forceId, cardId, e.target.value, notificationCrusadePointsUpdateSuccess, notificationFailure)
    }
    const onExperiencePointsUpdate = (e) => {
        updateExperiencePoints(forceId, cardId, e.target.value, notificationExperiencePointsUpdateSuccess, notificationFailure)
    }
    const onRankUpdate = (e) => {
        updateRank(forceId, cardId, e.target.value, notificationRankUpdateSuccess, notificationFailure)
    }
    const onBattlesPlayedUpdate = (e) => {
        updateBattlesPlayed(forceId, cardId, e.target.value, notificationBattlesPlayedUpdateSuccess, notificationFailure)
    }
    const onBattlesSurvivedUpdate = (e) => {
        updateBattlesSurvived(forceId, cardId, e.target.value, notificationBattlesSurvivedUpdateSuccess, notificationFailure)
    }
    const onEnemyUnitsDestroyedUpdate = (e) => {
        updateEnemyUnitsDestroyed(forceId, cardId, e.target.value, notificationEnemyUnitsDestroyedUpdateSuccess, notificationFailure)
    }
    const onBattleHonorsUpdate = (e) => {
        updateBattleHonors(forceId, cardId, e.target.value, notificationBattleHonorsUpdateSuccess, notificationFailure)
    }
    const onBattleScarsUpdate = (e) => {
        updateBattleScars(forceId, cardId, e.target.value, notificationBattleScarsUpdateSuccess, notificationFailure)
    }

    return (
        <>
            <Auth/>

            <p className="forceCardEditTitle">Card Update {card && card.name}</p>

            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  style={{width: '100%'}}
                  className="forceCardEditForm"
            >

                <Form.Item hidden="true" name="forceId">
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name"
                    id="name">
                    <Input onBlur={onNameUpdate} className="forceCardEditInput"/>
                </Form.Item>

                <Factions setSelectedFaction={setSelectedFaction}
                          onFactionUpdate={onFactionUpdate}/>

                <Units selectedFaction={selectedFaction}
                       selectedUnit={card?.unit}
                       onUnitUpdate={onUnitUpdate} />

                <Form.Item
                    label="Keywords"
                    name="keywords">
                    <TextArea onBlur={onKeywordsUpdate} className="forceCardEditInput"/>
                </Form.Item>

                <Form.Item
                    label="Equipments"
                    name="equipments">
                    <TextArea onBlur={onEquipmentsUpdate} className="forceCardEditInput"/>
                </Form.Item>

                <Form.Item
                    label="Enhancements"
                    name="enhancements">
                    <TextArea onBlur={onEnhancementsUpdate} className="forceCardEditInput"/>
                </Form.Item>


                <Form.Item
                    label="Points"
                    name="points">
                    <InputNumber onBlur={onPointsUpdate} defaultValue={0} className="forceCardEditInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Crusade Points"
                    name="crusadePoints">
                    <InputNumber onBlur={onCrusadePointsUpdate} className="forceCardEditInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Experience Points"
                    name="experiencePoints">
                    <InputNumber onBlur={onExperiencePointsUpdate} className="forceCardEditInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Rank"
                    name="rank">
                    <Input onBlur={onRankUpdate} className="forceCardEditInput"/>
                </Form.Item>


                <Form.Item
                    label="Battles Played"
                    name="battlesPlayed">
                    <InputNumber onBlur={onBattlesPlayedUpdate} className="forceCardEditInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Battles Survived"
                    name="battlesSurvived">
                    <InputNumber onBlur={onBattlesSurvivedUpdate} className="forceCardEditInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Enemy Units Destroyed"
                    name="enemyUnitsDestroyed">
                    <InputNumber onBlur={onEnemyUnitsDestroyedUpdate} className="forceCardEditInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Battle Honors"
                    name="battleHonors">
                    <TextArea onBlur={onBattleHonorsUpdate} className="forceCardEditInput"/>
                </Form.Item>

                <Form.Item
                    label="Battle Scars"
                    name="battleScars">
                    <TextArea onBlur={onBattleScarsUpdate} className="forceCardEditInput"/>
                </Form.Item>

                <Form.Item className="forceCardEditButton">
                    <Button onClick={navigateBack}>
                        Back to Force
                    </Button>
                </Form.Item>

            </Form>
        </>
    );
};

export default CardManage;

