import React from "react";
import {Route, Routes} from "react-router-dom";
import ForceCreate from "./create/ForceCreate";
import ForceView from "./view/ForceView";
import CardCreate from "../card/create/CardCreate";
import ForceManage from "./manage/ForceManage";
import MyForces from "./my/MyForces";
import ForcePrint from "./print/ForcePrint";


export function ForceRouter() {
    return (
        <Routes>
            <Route path="/crusade/force/create" element={ <ForceCreate /> }  />
            <Route path="/crusade/force/:forceId/view" element={ <ForceView /> }  />
            <Route path="/crusade/force/:forceId/manage" element={ <ForceManage /> }  />
            <Route path="/crusade/force/:forceId/print" element={ <ForcePrint /> }  />
            <Route path="/crusade/force/:forceId/unit/create" element={ <CardCreate /> }  />
            <Route path="/crusade/forces/my" element={ <MyForces /> }  />
        </Routes>
    );
}


