import axios from "axios";
import {getToken} from "../user/auth/Auth";
import {restRoute} from "../AppUtils";



export function allUsers() {
    return axios
        .get(restRoute() + '/tournament/users', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function allGames() {
    return axios
        .get(restRoute() + '/tournament/games', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function createTournament(tournament) {
    return axios
        .post(restRoute() + '/tournament/create', tournament, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function updateTournament(tournament) {
    return axios
        .put(restRoute() + '/tournament/update', tournament, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function tournamentView(tournamentId) {
    return axios
        .get(restRoute() + '/tournament/view/' + tournamentId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function tournamentBoard(tournamentId) {
    return axios
        .get(restRoute() + '/tournament/liveboard/' + tournamentId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function loadTournaments(created, master, participated, all) {
    return axios
        .get(restRoute() + '/tournaments', {
            params: {
                created: created,
                master: master,
                participated: participated,
                all: all
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function myCreatedTournaments() {
    return axios
        .get(restRoute() + '/tournaments/my/created', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
export function myMasterTournaments() {
    return axios
        .get(restRoute() + '/tournaments/my/master', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
export function isRegistered(tournamentId) {
    return axios
        .get(restRoute() + '/tournament/isregistered/' + tournamentId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function register(tournamentId, faction) {
    return axios
        .post(restRoute() + '/tournament/register/' + tournamentId, {
            faction: faction,
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}


export function unregister(tournamentId) {
    return axios
        .post(restRoute() + '/tournament/unregister/' + tournamentId, {}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function codexFactions(universe) {
    return axios
        .get(restRoute() + '/codex/factions', {
            params: {universe: universe},
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}


export function codexUniverses() {
    return axios
        .get(restRoute() + '/codex/universes', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}


