import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {gameView} from "../../game/GameConnector";
import {tournamentBoard, tournamentView} from "../TournamentConnector";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Space, Table} from "antd";
import moment from "moment/moment";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";

const TournamentBoard = () => {

    GoogleAnalyticsPageView("/tournament/board", "Tournament Board")

    let {tournamentId} = useParams();
    const navigate = useNavigate();


    const [loading, setLoading] = useState(true)
    const [tournament, setTournament] = useState({});

    const [players, setPlayers] = useState([]);
    const [games, setGames] = useState([]);

    const [random, setRandom] = useState(0);

    const [reload, setReload] = useState(undefined);


    useEffect(() => {
        load()
        setRandom(Math.random())
    }, [])

    useEffect(() => {
        if (reload) {
            load()
        }
    }, [reload])


    useEffect(() => {
        loadBoard()
    }, [random])

    const loadBoard = () => {
        tournamentBoard(tournamentId)
            .then(response => {
                setReload(response)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setRandom(Math.random())
            })
    }


    const load = () => {
        tournamentView(tournamentId)
            .then(response => {
                setTournament(response.body)

                //

                let tournamentGames = response.body.games;

                if (tournamentGames === undefined || tournamentGames === null || tournamentGames.length === 0) {

                } else {

                    for (let i = 0; i < tournamentGames.length; i++) {
                        tournamentGames[i] = {
                            id: tournamentGames[i].id,
                            name: tournamentGames[i].name,
                            value: tournamentGames[i].id,
                            label: tournamentGames[i].name,
                            winner: tournamentGames[i].gameWinnerUsername === 'None' ? '' : tournamentGames[i].gameWinnerUsername,
                            key: tournamentGames[i].key,
                            date: tournamentGames[i].dateTime && moment(tournamentGames[i].dateTime, 'YYYYMMDD').format('DD MMM YYYY'),
                        }
                    }

                    setGames(tournamentGames)
                }

                //

                let tournamentPlayers = response.body.players;

                if (tournamentPlayers === undefined || tournamentPlayers === null || tournamentPlayers.length === 0) {

                } else {

                    for (let i = 0; i < tournamentPlayers.length; i++) {
                        tournamentPlayers[i] = {
                            id: tournamentPlayers[i].user.id,
                            name: tournamentPlayers[i].user.username,
                            value: tournamentPlayers[i].user.id,
                            label: tournamentPlayers[i].user.username,
                            key: tournamentPlayers[i].user.key,
                            score: tournamentPlayers[i].score,
                            wins: tournamentPlayers[i].stats.wins,
                            draws: tournamentPlayers[i].stats.draws,
                            losses: tournamentPlayers[i].stats.losses
                        }
                    }

                    setPlayers(tournamentPlayers.sort((p1, p2) => p2.score - p1.score))
                }
                //

                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const usersTableColumns = [{
        title: 'Name', dataIndex: 'label', key: 'key', width: '40%',
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '30%',
    },{
        title: 'Score', dataIndex: 'score', key: 'key', width: '15%',
    },{
        title: 'Wins', dataIndex: 'wins', key: 'key', width: '5%',
    },{
        title: 'Draws', dataIndex: 'draws', key: 'key', width: '5%',
    },{
        title: 'Losses', dataIndex: 'losses', key: 'key', width: '5%',
    }];

    const gamesTableColumns = [{
        title: 'Name', dataIndex: 'name', key: 'key', width: '50%',
    },{
        title: 'Winner', dataIndex: 'winner', key: 'winner', width: '25%',
    },{
        title: 'Date', dataIndex: 'date', key: 'date', width: '25%',
    },];

    return (
        <>
            <Auth/>
            <h2 className="tournBoardTitle">Tournament Board <span data-cy="name">{tournament.name}</span></h2>
            {loading && <p>loading</p>}
            {!loading &&
                <Space direction="vertical" className="tournView">

                    {(loading || players.length === 0) &&
                        <>
                            <h2 className="tournBoardTableTitles">Participants:</h2>
                            <div className="tournViewNoTable"> No Participants Found</div>
                        </>
                    }
                    {(!loading && players.length > 0) && <Form.Item name="usersFormItem">
                        <h2 className="tournBoardTableTitles">Participants:</h2>
                        <div className="tournViewTable">
                            <Table
                                columns={usersTableColumns}
                                dataSource={players}
                                pagination={
                                    {
                                        pageSize: 10
                                    }
                                }
                                id={"usersTable"}
                                style={{width: '100%', minWidth: '42rem'}}
                                showHeader={true}
                            />
                        </div>
                    </Form.Item>
                    }

                    {(loading || games.length === 0) && <>
                        <h2 className="tournBoardTableTitles">Games:</h2>
                        <div className="tournViewNoTable"> No Games Found</div>
                    </>}
                    {(!loading && games.length > 0) && <Form.Item name="gamesFormItem">
                        <h2 className="tournBoardTableTitles">Games:</h2>
                        <div className="tournViewTable">
                            <Table
                                columns={gamesTableColumns}
                                dataSource={games}
                                pagination={
                                    {
                                        pageSize: 10
                                    }
                                }
                                id={"gamesTable"}
                                style={{width: '100%', minWidth: '42rem'}}
                                showHeader={true}
                            />
                        </div>
                    </Form.Item>
                    }

                </Space>
            }
        </>
    );
};

export default TournamentBoard;

