import React, {useEffect, useState} from "react";
import {
    Dropdown, List, Space, Typography
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {Auth} from "../../user/auth/Auth";
import {codexFactions, loadUnitsByFaction} from "../CodexConnector";
import {DownOutlined} from '@ant-design/icons';
import Title from "antd/lib/typography/Title";


const CodexUnits = () => {

    GoogleAnalyticsPageView("/codex/units", "Codex Units")

    const {Text, Link} = Typography;

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [factions, setFactions] = useState({})
    const [units, setUnits] = useState([])
    const [factionItems, setFactionItems] = useState([])
    const [selectedFaction, setSelectedFaction] = useState("Select faction")

    useEffect(() => {
        codexFactions()
            .then(response => {
                setFactions(response.body)
                setLoading(false)

            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    useEffect(() => {

        let tempItems = []
        for (let i = 0; i < factions.length; i++) {
            tempItems[i] = {
                key: factions[i],
                label: factions[i]
            }
        }

        setFactionItems(tempItems)

    }, [factions]);

    useEffect(() => {

        loadUnitsByFaction(selectedFaction)
            .then(response => {
                setUnits(response.body)
            })
            .catch(error => {
                console.log(error)
            })

    }, [selectedFaction])

    const onClick = ({key}) => {
        setSelectedFaction(key)
    };

    return (
        <>
            <Auth/>

            <p className="">Codex Units</p>

            {factionItems && <Dropdown
                menu={{
                    items: factionItems,
                    onClick
                }}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        {selectedFaction} <DownOutlined/>
                    </Space>
                </a>
            </Dropdown>}

            {units && <List
                bordered
                dataSource={units}
                renderItem={(item) => (
                    <List.Item>
                        <Space direction="vertical" align="start">
                            <Space direction="horizontal">
                                {item.name} <Text strong>{JSON.stringify(item.prices)}</Text>
                            </Space>
                            <Space direction="horizontal">
                                {item.m} {item.t} {item.sv} {item.w} {item.ld} {item.oc}
                            </Space>
                            <Space direction="vertical" align="start">
                                {item.models.map(model =>
                                    <Space direction="vertical" align="start">
                                        {model.name}
                                        <Space direction="horizontal">&nbsp;
                                            {model.weapons
                                                .map(weapon => weapon.defaultWeapon ? <Text strong>{weapon.name}</Text> : <Text>{weapon.name}</Text>)
                                                }
                                        </Space>
                                    </Space>)}
                            </Space>
                        </Space>
                    </List.Item>
                )}
            />}

        </>
    );
};

export default CodexUnits;

