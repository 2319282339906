import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input, InputNumber,
    Select,
    Table
} from "antd";
import {useNavigate, useParams} from "react-router-dom";

import {calculateUnitPrice, createCard} from "../CardConnector";
import {Auth} from "../../../user/auth/Auth";
import TextArea from "antd/es/input/TextArea";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import Factions from "./components/Factions";
import Units from "./components/Units";
import Keywords from "./components/Keywords";


const CardCreate = () => {

    GoogleAnalyticsPageView("/crusade/card/create", "Crusade Card Create")

    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [selectedUnit, setSelectedUnit] = useState({})

    let {forceId} = useParams();

    const [selectedFaction, setSelectedFaction] = useState();

    useEffect(() => {
        form.setFieldsValue({forceId: forceId});
    }, []);

    useEffect(() => {
        if (selectedUnit !== undefined && selectedUnit !== null) {
            calculatePoints(selectedUnit)
        }
    }, [selectedUnit])

    const save = (card) => {
        createCard(card)
            .then(response => {
                    if (response.valid) {
                        navigate('/crusade/force/' + forceId + '/manage')
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    const calculatePoints = (unit) => {
        calculateUnitPrice(unit)
            .then(response => {
                    if (response.valid) {
                        form.setFieldsValue({
                            points: response.body
                        })
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    const onUnitsChange = () => {
        if (selectedUnit !== undefined && selectedUnit !== null) {
            calculatePoints(selectedUnit)
            form.setFieldsValue({
                unit: selectedUnit
            })
        }
    }

    function onFinish(values) {
        save(values)
    }

    function navigateBack() {
        navigate("/crusade/force/" + forceId + "/manage")
    }

    return (
        <>
            <Auth/>

            <p className="forceCardCreateTitle">Card Create</p>

            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  style={{width: '100%'}}
                  className="forceCardCreateForm"
                  >

                <Form.Item hidden="true" name="forceId">
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name">
                    <Input data-cy="name" className="forceCardCreateInput"/>
                </Form.Item>

                <Factions setSelectedFaction={setSelectedFaction}/>
                <Units form={form} selectedFaction={selectedFaction} setSelectedUnit={setSelectedUnit} onUnitsChange={onUnitsChange}/>
                <Keywords form={form} selectedFaction={selectedFaction} selectedUnit={selectedUnit}/>

                <Form.Item
                    label="Equipments"
                    name="equipments">
                    <TextArea className="forceCardCreateInput"/>
                </Form.Item>

                <Form.Item
                    label="Enhancements"
                    name="enhancements">
                    <TextArea className="forceCardCreateInput"/>
                </Form.Item>


                <Form.Item
                    label="Points"
                    name="points">
                    <InputNumber defaultValue={0} className="forceCardCreateInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Crusade Points"
                    name="crusadePoints">
                    <InputNumber defaultValue={0} className="forceCardCreateInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Experience Points"
                    name="experiencePoints">
                    <InputNumber defaultValue={0} className="forceCardCreateInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Rank"
                    name="rank">
                    <Input className="forceCardCreateInput"/>
                </Form.Item>


                <Form.Item
                    label="Battles Played"
                    name="battlesPlayed">
                    <InputNumber defaultValue={0} className="forceCardCreateInputNum"/>
                </Form.Item>



                <Form.Item
                    label="Battles Survived"
                    name="battlesSurvived">
                    <InputNumber defaultValue={0} className="forceCardCreateInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Enemy Units Destroyed"
                    name="enemyUnitsDestroyed">
                    <InputNumber defaultValue={0} className="forceCardCreateInputNum"/>
                </Form.Item>


                <Form.Item
                    label="Battle Honors"
                    name="battleHonors">
                    <TextArea className="forceCardCreateInput"/>
                </Form.Item>

                <Form.Item
                    label="Battle Scars"
                    name="battleScars">
                    <TextArea className="forceCardCreateInput"/>
                </Form.Item>


                <Form.Item className="forceCardEditButton">
                    <Button onClick={navigateBack}>
                        Back to Force
                    </Button>
                </Form.Item>

                <Form.Item>
                    <Button data-cy="submit"
                            type="primary"
                            htmlType="submit"
                            className="forceCardCreateButton">
                        Submit
                    </Button>
                </Form.Item>

            </Form>
        </>
    );
};

export default CardCreate;

