import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input,
    Select, Space,
    Table
} from "antd";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {allGames, allUsers, createTournament} from "../TournamentConnector";
import {createGame} from "../../game/GameConnector";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Factions from "./components/Factions";
import Universes from "./components/Universes";
const { TextArea } = Input;

const TournamentCreate = () => {

    GoogleAnalyticsPageView("/tournament/create", "Tournament Create")

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState([]);

    const [gamesLoading, setGamesLoading] = useState(true)
    const [games, setGames] = useState([]);

    const [selectedRowKeys, setRowKeys] = useState();

    const [selectedUniverse, setSelectedUniverse] = useState()

    useEffect(() => {
        loadUsersEffect()
        loadGamesEffect()
    }, [])

    useEffect(() => {
        form.setFieldsValue({universe: selectedUniverse});
    }, [selectedUniverse])

    const loadUsersEffect = () => {
        allUsers()
            .then(response => {
                for (let i = 0; i < response.body.length; i++) {
                    response.body[i] = {
                        id: response.body[i].id,
                        name: response.body[i].username,
                        value: response.body[i].id,
                        label: response.body[i].username,
                        key: response.body[i].key,
                        faction: ''
                    }
                }

                setUsers(response.body)
                setUsersLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const loadGamesEffect = () => {
        allGames()
            .then(response => {
                for (let i = 0; i < response.body.length; i++) {
                    response.body[i] = {
                        id: response.body[i].id,
                        name: response.body[i].name,
                        value: response.body[i].id,
                        label: response.body[i].name,
                        date: response.body[i].date,
                        winner: response.body[i].winner,
                        key: response.body[i].key
                    }
                }

                setGames(response.body)
                setGamesLoading(false)

            })
            .catch(error => {
                console.log(error)
            })
    }


    const save = (tournament) => {
        createTournament(tournament)
            .then(response => {
                    if (response.valid) {
                        navigate('/tournament/view/' + response.body.id)
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    function onFinish(values) {
        save(values)
    }


    const usersTableColumns = [{
        title: 'Name', dataIndex: 'label', key: 'key', width: '60%',
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '40%',
        render: (_, row) => (
            <Factions selectedUniverse={selectedUniverse} setSelectedFaction={(value) => {
                row.faction = value
                let fieldValue = form.getFieldValue("users");

                fieldValue && fieldValue.forEach((field) => {
                    if (field.userId === row.id) {
                        field.faction = row.faction
                    }
                 })

            }} />
        )
    }];

    const gamesTableColumns = [{
        title: 'Name', dataIndex: 'name', key: 'key', width: '50%',
    },{
        title: 'Winner', dataIndex: 'winner', key: 'key', width: '25%',
    },{
        title: 'Date', dataIndex: 'date', key: 'date', width: '25%',
    },
    ];

    const gamesRowSelection = {
        selectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {

            if (selectedRowKeys.length === 0) {
                form.setFieldsValue({gameIds: []});
            } else {
                form.setFieldsValue({gameIds: selectedRows.map(row => row.id)});
            }
        },
    };

    const usersRowSelection = {
        selectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {

            if (selectedRowKeys.length === 0) {
                form.setFieldsValue({users: []});
            } else {
                let obj = []
                selectedRows.map((row, index) => {
                    obj.push({"userId": row.id, "faction": row.faction})
                });
                form.setFieldsValue({users: obj});
            }
        },
    };

    function onTournamentMasterSelect() {

    }

    function navigateBack() {
        navigate("/tournaments")
    }
    return (
        <>
            <Auth />
            <h2 className="tournCreateTitle">Create New Tournament</h2>
            <Form
                id={"form"}
                onFinish={onFinish}
                form={form}
                layout="horizontal"
            >

                <Space className="tournCreateForm" direction={"vertical"}>
                    <Form.Item
                        className="tournCreateLabel"
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input username!',
                            },
                        ]}
                    >
                        <Input className="tournCreateInput" data-cy="name"/>
                    </Form.Item>
                    <Space direction={"horizontal"}>
                        <Space className="tournCreateDatesLeft" direction={"vertical"}>
                            <Form.Item
                                className="tournCreateLabelLong"
                                label="Registration Start Date"
                                name="registrationStartDate">

                                <DatePicker className="tournCreateDate"/>
                            </Form.Item>

                            <Form.Item
                                className="tournCreateLabelLong tournCreateNoMarginBottom"
                                label="Registration End Date"
                                name="registrationEndDate">

                                <DatePicker className="tournCreateDate"/>
                            </Form.Item>

                        </Space>
                        <Space className="tournCreateDatesRight" direction={"vertical"}>
                            <Form.Item
                                label="Start Date"
                                name="startDate">

                                <DatePicker className="tournCreateDate"/>
                            </Form.Item>

                            <Form.Item
                                label="End Date"
                                name="endDate">

                                <DatePicker className="tournCreateDate"/>
                            </Form.Item>

                        </Space>
                    </Space>
                    <Form.Item
                        className="tournCreateLabel"
                        label="Universe"
                        name="universe"
                        style={{width: '10.3rem', justifyContent: 'start', display: 'flex'}}
                    >
                        <Universes setSelectedUniverse={setSelectedUniverse}/>
                    </Form.Item>

                    <Form.Item
                        className="tournCreateLabel"
                        label="Tournament Master" name="tournamentMasterId">
                        <Select
                            options={users}
                            onSelect={onTournamentMasterSelect}
                            className="tournCreateSelector"
                        />
                    </Form.Item>

                    <Form.Item
                        className="tournCreateLabel"
                        label="Notes"
                        name="notes">
                        <TextArea rows={6} maxLength={1000}/>
                    </Form.Item>
                </Space>
                
                <Form.Item name="gameIds" hidden={true} />
                <Form.Item name="userIds" hidden={true} />
                <Form.Item name="users" hidden={true} />

                { (usersLoading || users.length === 0) && 
                    <>
                    <h2 className="tournCreateTableTitles">Participants:</h2>
                    <div className="tournCreateNoTable"> No Participants Found </div> 
                    </>
                }
                { (!usersLoading && users.length > 0) && 
                    <>
                    <h2 className="tournCreateTableTitles">Participants:</h2>
                    <Form.Item name="usersFormItem">
                        <Table
                            columns={usersTableColumns}
                            dataSource={users}
                            pagination={
                            {
                                pageSize: 10
                            }
                            }
                            id={"usersTable"}
                            style={{width: '100%'}}
                            bordered
                            showHeader={true}
                            rowSelection={{
                                type: 'checkbox', ...usersRowSelection,
                            }}
                            className="tournCreateTable"
                        />
                    </Form.Item> 
                    </>
                }

                { (gamesLoading || games.length === 0) && 
                    <>
                    <h2 className="tournCreateTableTitles">Games:</h2>
                    <div className="tournCreateNoTable"> No Games Found </div> 
                    </>
                }
                
                { (!gamesLoading && games.length > 0) && 
                    <>
                    <h2 className="tournCreateTableTitles">Games:</h2>
                    <Form.Item name="gamesFormItem">
                        <Table
                            columns={gamesTableColumns}
                            dataSource={games}
                            pagination={
                            {
                                pageSize: 10
                            }
                            }
                            id={"gamesTable"}
                            style={{width: '100%'}}
                            bordered
                            showHeader={true}
                            rowSelection={{
                                type: 'checkbox', ...gamesRowSelection,
                            }}
                            className="tournCreateTable"
                        />
                    </Form.Item> 
                    </>
                }
                <Form.Item>
                    <Space className="tournCreateButtons" direction="horizontal">
                        <Button onClick={navigateBack}>Back</Button>
                        <Button type="primary" htmlType="submit" data-cy="submit" >
                            Create
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
};

export default TournamentCreate;

