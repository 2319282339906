import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {
    deleteCard, deleteForce, deleteGameResult,
    forceView,
    updateBattleTally,
    updateName, updateReqPoints,
    updateSupplyLimit,
    updateSupplyUsed,
    updateVictories
} from "../ForceConnector";
import {Auth} from "../../../user/auth/Auth";
import {Button, Form, Input, InputNumber, Popover, Space, Table} from "antd";
import {CheckOutlined} from '@ant-design/icons';
import moment from "moment/moment";
import {updateType} from "../../card/CardConnector";
import {notificationFailure, notificationTypeUpdateSuccess} from "../../card/CardNotifications";
import {
    notificationBattleTallyUpdateSuccess,
    notificationNameUpdateSuccess,
    notificationReqPointsUpdateSuccess, notificationSupplyLimitUpdateSuccess, notificationSupplyUsedUpdateSuccess,
    notificationVictoriesUpdateSuccess
} from "../ForceNotifications";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";

const ForceManage = () => {

    GoogleAnalyticsPageView("/crusade/force/manage", "Crusade Force Manage")

    const navigate = useNavigate();

    const [form] = Form.useForm();

    let {forceId} = useParams();

    const [loading, setLoading] = useState(true)
    const [force, setForce] = useState({});
    const [cards, setCards] = useState([]);
    const [gameResults, setGameResults] = useState([]);

    useEffect(() => {
        loadForce()
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            name: force.name,
            supplyLimit: force.supplyLimit,
            supplyUsed: force.supplyUsed,
            battleTally: force.battleTally,
            victories: force.victories,
            reqPoints: force.reqPoints
        })
    }, [force])

    const loadForce = () => {
        forceView(forceId)
            .then(response => {
                setForce(response.body)
                setCards(response.body.cards)
                setGameResults(response.body.gameResults)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const onAddUnit = () => {
        navigate("/crusade/force/" + force.id + "/unit/create")
    };

    function navigateBack() {
        navigate("/crusade/force/" + forceId + "/view")
    }

    const onManageUnit = (cardId) => {
        navigate("/crusade/force/" + force.id + "/card/" + cardId + "/manage")
    };

    const onAddGameResults = () => {
        navigate("/crusade/force/" + force.id + "/gameresults/create")
    };

    const onCardDelete = (forceId, cardId) => {
        deleteCard(forceId, cardId)
            .then(response => {
                loadForce();
            })
    }

    const onForceDelete = (forceId) => {
        deleteForce(forceId)
            .then(response => {
                navigate("/crusade/forces/my")
            })
    }

    const onGameResultDetailsDelete = (gameResultId) => {
        deleteGameResult(forceId, gameResultId)
            .then(response => {
                loadForce();
            })
    }

    const onNameUpdate = (e) => {
        updateName(forceId, e.target.value, notificationNameUpdateSuccess, notificationFailure)
    }

    const onReqPointsUpdate = (e) => {
        updateReqPoints(forceId, e.target.value, notificationReqPointsUpdateSuccess, notificationFailure)
    }
    const onVictoriesUpdate = (e) => {
        updateVictories(forceId, e.target.value, notificationVictoriesUpdateSuccess, notificationFailure)
    }
    const onBattleTallyUpdate = (e) => {
        updateBattleTally(forceId, e.target.value, notificationBattleTallyUpdateSuccess, notificationFailure)
    }
    const onSupplyUsedUpdate = (e) => {
        updateSupplyUsed(forceId, e.target.value, notificationSupplyUsedUpdateSuccess, notificationFailure)
    }
    const onSupplyLimitUpdate = (e) => {
        updateSupplyLimit(forceId, e.target.value, notificationSupplyLimitUpdateSuccess, notificationFailure)
    }

    const gameResultDetails = (gameResult) => {
        const listItems = gameResult.cards && gameResult.cards.map((card) =>
            <p>{card.card.name} | {card.survived && 'survived | '} destroyed: {card.enemyUnitsDestroyed} {card.markOfGreatness && ' | mark'}</p>
        );

        let div = <>
            <div>
                {listItems}
            </div>
        </>;

        return div
    }


    const cardsColumns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
    }, {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button onClick={() => onManageUnit(record.id)}>Edit</Button>
                <Button onClick={() => onCardDelete(forceId, record.id)}
                        className="forceManageDeleteButton">Delete</Button>
            </Space>
        ),
    },];

    const gameResultsColumns = [{
        title: 'Date', dataIndex: 'date', key: 'date',
        render: (_, row) => (
            moment(row.date, 'YYYYMMDD').format('DD MMM YYYY')
        )
    }, {
        title: 'Winner', dataIndex: 'winner', key: 'winner',
        render: (_, row) => (
            row.winner && <CheckOutlined/>
        )

    }, {
        title: 'Actions', dataIndex: 'actions', key: 'actions',
        render: (_, row) => (
            <>
                <Space size="middle">
                    <Popover content={gameResultDetails(row)} title="Details">
                        <Button>Details</Button>
                    </Popover>
                    <Button onClick={() => onGameResultDetailsDelete(row.id)}
                            className="forceManageDeleteButton">Delete</Button>
                </Space>
            </>
        )
    }
    ];


    return (
        <>
            <Auth/>

            <p className="forceManageTitle"> Manage Force {force.name}</p>

            <Form
                form={form}
                className="forceManageForm"
            >

                <Form.Item hidden="true" name="forceId">
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name">
                    <Input onBlur={onNameUpdate} className="forceManageInput"/>
                </Form.Item>

                <Space direction="horizontal" className="forceManageStats">
                    <Space direction="vertical">
                        <p>Supply Limit</p>
                        <Form.Item name={"supplyLimit"}>
                            <InputNumber min={0} max={9999} onBlur={onSupplyLimitUpdate}/>
                        </Form.Item>
                    </Space>
                    <Space direction="vertical">
                        <p>Supply Used</p>
                        <Form.Item name={"supplyUsed"}>
                            <InputNumber min={0} max={10} onBlur={onSupplyUsedUpdate}/>
                        </Form.Item>
                    </Space>
                    <Space direction="vertical">
                        <p>Battle Tally</p>
                        <Form.Item name={"battleTally"}>
                            <InputNumber min={0} max={10} onBlur={onBattleTallyUpdate}/>
                        </Form.Item>
                    </Space>
                    <Space direction="vertical">
                        <p>Victories</p>
                        <Form.Item name={"victories"}>
                            <InputNumber min={0} max={10} onBlur={onVictoriesUpdate}/>
                        </Form.Item>
                    </Space>
                    <Space direction="vertical">
                        <p>Requisition Points</p>
                        <Form.Item name={"reqPoints"}>
                            <InputNumber min={0} max={10} onBlur={onReqPointsUpdate}/>
                        </Form.Item>
                    </Space>
                </Space>

                <p className="forceManageTableTitles">Cards</p>
                <Table
                    columns={cardsColumns}
                    dataSource={cards}
                    pagination={{
                        pageSize: 10
                    }}
                    id={"table"}
                    style={{width: '100%'}}
                    showHeader={true}
                />

                <div className="forceManageCardButton">
                    <Button
                        data-cy="addcard"
                        onClick={onAddUnit}
                        className="forceManageCardButton">Add Card</Button>
                </div>

                <p className="forceManageTableTitles">Game Results</p>
                {force.gameResults === null && <p>no data</p>}
                {(force.gameResults !== null) && <Table
                    columns={gameResultsColumns}
                    dataSource={force.gameResults}
                    pagination={{
                        pageSize: 10
                    }}
                    id={"table"}
                    style={{width: '100%'}}
                    showHeader={true}
                    bordered={true}
                    className="forceViewResultsTable"
                />}

                <div className="forceManageResultButton">
                    <Button onClick={navigateBack}>Back</Button>
                    <Button onClick={onAddGameResults}>Add Game Results</Button>
                    <Button onClick={() => onForceDelete(forceId)} className="forceManageDeleteButton">Delete Force</Button>
                </div>

            </Form>

        </>
    );
};

export default ForceManage;

