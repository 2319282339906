import React from "react";
import {Route, Routes} from "react-router-dom";
import TagsMy from "./my/TagsMy";
import AssignUnit from "./assignunit/AssignUnit";


export function TagRouter() {
    return (
        <Routes>
            <Route path="/tags/my" element={ <TagsMy /> }  />
            <Route path="/tag/assign/unit" element={ <AssignUnit /> }  />
        </Routes>
    );
}


